<!--
  便于管理所有表格的样式和通用功能，支持内部表格属性的继承

  集成了一些功能：
   1. 底部悬浮的滚动条：表格有横向滚动条且数据需要下拉时候需要拉倒底部才能横向滚动，该功能提供一个悬浮的滚动条便于横向滚动表格
   2. 缓存列的显示隐藏（需要给table组件传id属性，否则不会缓存）
   3. 表格全屏功能
   4. 表格分页组件 工具栏集成
   5. 列宽缓存（未实现）
 -->
<template>
  <div ref="tableWrapper" :style="wrapperStyle">
    <div class="bg-white">
      <!-- 工具栏 -->
      <div v-if="toolsbar" class="toolbar-wrapper">
        <vxe-toolbar ref="xToolbar" size="medium">
          <template #buttons>
            <slot name="buttons">
            </slot>
          </template>
          <template #tools>
            <el-button class="mx-10" size="mini" :icon="fullScreenIcon" circle @click="handleFullscreen" title="全屏表格"></el-button>

            <el-popover
              v-model="visible"
              @show="show"
              @hide="hide"
              placement="bottom"
              width="200"
              trigger="click">
              <div class="flex flex-column flex-nowrap" v-loading="columnLoading">
                <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
                <div style="height:1px; background: #d9dddf; margin: 8px 0;"></div>
                <el-checkbox-group class="flex flex-column" v-model="checkedColumns" @change="handleCheckedCitiesChange">
                  <el-checkbox style="margin: 2px 0;" v-for="item in columns" :key="item.property" :label="item.property">{{item.title}}</el-checkbox>
                </el-checkbox-group>
                <div style="height:1px; background: #d9dddf; margin: 8px 0;"></div>
                <div class="flex align-center justify-around" >
                  <el-link :underline="false" @click="handleConfirm">确认</el-link>
                  <el-link :underline="false" @click="handleReset">还原</el-link>
                </div>
              </div>
              <el-button class="mx-8" slot="reference" size="mini" icon="fs-16 el-icon-s-grid" circle title="自定义展示列"/>
            </el-popover>
          </template>
        </vxe-toolbar>
      </div>

      <vxe-table
        v-horizontalScroll
        ref="xTable"
        auto-resize
        resizable
        :column-config="{resizable: true, isCurrent: true, isHover: true}"
        :seq-config="tablePage?{startIndex:(tablePage.pageNum-1)*tablePage.pageSize}:undefined"
        show-overflow="title"
        highlight-hover-row
        highlight-current-row
        :size="size"
        :align="align"
        :headerCellStyle="headerCellStyle"
        @resizable-change="resizableChangeEvent"
        v-on="$listeners"
        v-bind="$attrs">
        <slot></slot>
      </vxe-table>

      <div v-if="tablePage" class="pager-wrapper flex justify-end">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="tablePage.pageNum"
          :page-sizes="[10, 15, 20, 50, 100, 500, 1000]"
          :page-size="tablePage.pageSize"
          layout="total, prev, pager, next, jumper, sizes"
          :total="tablePage.totalSize">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
// import localforage from 'localforage'
import { setJsonData, getJsonData } from '@/apis/common'
import { mapState, mapMutations } from 'vuex'
export default {
  props: {
    // 分页
    tablePage: { type: Object },
    // 配置
    toolsbar: { type: Boolean, default: true },
    // 表格配置默认值
    size: { default: 'medium' },
    align: { default: 'left' },
    headerCellStyle: { default () { return { 'background-color': '#fafafa' } } }
  },
  computed: {
    fullScreenIcon () {
      const iconClass = this.isFullScreen ? 'el-icon-zoom-out fs-16' : ' fs-16 el-icon-zoom-in'
      return 'fs-16 ' + iconClass
    },
    ...mapState('tableStorage', ['jsonData'])
  },
  data () {
    return {
      isFullScreen: false,
      wrapperStyle: undefined,
      columns: [],
      checkAll: false,
      checkedColumns: [],
      isIndeterminate: false,
      visible: false,
      xTableId: '',
      // cacheData: null,
      columnLoading: false
    }
  },
  mounted () {
    if (this.toolsbar) {
      this.$refs.xTable.connect(this.$refs.xToolbar)
      // 初始化列
      this.xTableId = this.$refs.xTable.$options.propsData.id
      this.$nextTick(async () => {
        this.columns = this.$refs.xTable.getColumns().filter(item => item.property)

        this.initList(true)
      })
      if (!this.xTableId) {
        console.warn('当前baseTable未传id，配置将不会进行缓存，页面路由：' + this.$route.path)
      }
    }

    // esc 取消全屏表格
    document.addEventListener('keydown', this.handleEsc, false)
    this.$once('hook:beforeDestroy', () => {
      document.removeEventListener('keydown', this.handleEsc, false)
    })
  },
  methods: {
    ...mapMutations('tableStorage', ['CHANGE_SETTING']),
    async getJsonData (prop) {
      const res = await getJsonData()
      let data = res?.data || ''
      if (data) {
        data = JSON.parse(data)
      }
      this.CHANGE_SETTING({ key: 'jsonData', value: data || null })
      // this.cacheData = data || null
      return data[prop]
    },
    async setJsonData (prop, propValue) {
      let jsonData = JSON.parse(JSON.stringify(this.jsonData)) || {}
      jsonData[prop] = propValue

      if (typeof jsonData !== 'string') jsonData = JSON.stringify(jsonData)
      return setJsonData({ json: jsonData || '' })
    },
    async handleConfirm () {
      if (this.columnLoading) return
      if (this.xTableId) {
        // await localforage.setItem(`column_${this.xTableId}`, JSON.stringify(this.checkedColumns))
        this.columnLoading = true
        await this.setJsonData(`column_${this.xTableId}`, this.checkedColumns)
        this.columnLoading = false
      }
      this.initList()
      this.visible = false
    },
    async handleReset () {
      if (this.columnLoading) return
      if (this.xTableId) {
        this.columnLoading = true
        await this.setJsonData(`column_${this.xTableId}`, '')
        this.columnLoading = false
      }
      this.initList()
      this.visible = false
    },
    async initList (isCreate) {
      let tableFields
      if (this.xTableId) {
        if (isCreate && this.jsonData) {
          tableFields = this.jsonData[`column_${this.xTableId}`]
        } else {
          tableFields = await this.getJsonData(`column_${this.xTableId}`)
          tableFields = tableFields || this.columns.map(item => item.property)
        }
      } else {
        tableFields = this.checkedColumns
      }
      this.$refs.xTable.resetColumn()
      if (!tableFields) return
      const columns = this.$refs.xTable.getColumns()

      columns.forEach(column => {
        if (column.property) {
          if (!tableFields.includes(column.property)) {
            column.visible = false
          }
        }
      })
      this.$refs.xTable.refreshColumn()
    },
    show () {
      this.checkedColumns = []
      const columns = this.$refs.xTable.getColumns()
      columns.forEach(column => {
        if (column.property && column.visible) {
          this.checkedColumns.push(column.property)
        }
      })
      this.handleCheckedCitiesChange(this.checkedColumns)
    },
    hide () {
      this.checkedColumns = []
    },

    handleCheckedCitiesChange (value) {
      const checkedCount = value.length
      this.checkAll = checkedCount === this.columns.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.columns.length
    },

    handleCheckAllChange (val) {
      this.checkedColumns = val ? this.columns.map(item => item.property) : []
      this.isIndeterminate = false
    },
    // 列宽
    resizableChangeEvent () {
      const columns = this.$refs.xTable.getColumns()
      const customData = columns.map(column => {
        return {
          width: column.renderWidth
        }
      })
      console.log(customData)
    },
    handleEsc (e) {
      if (e.keyCode == 27) {
        if (this.isFullScreen !== false) {
          this.handleFullscreen(false)
        }
      }
    },
    // 全屏, 通过样式来控制，注意层级
    handleFullscreen (isFullScreen) {
      if (isFullScreen === false) {
        this.isFullScreen = false
      } else {
        this.isFullScreen = !this.isFullScreen
      }

      // 修复悬浮滚动条的问题
      const event = new Event('resize')
      window.dispatchEvent(event)

      if (this.isFullScreen) {
        this.wrapperStyle = {
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 999,
          background: 'white'

        }
      } else {
        this.wrapperStyle = undefined
      }
    },
    // 分页
    handleSizeChange (value) {
      this.$emit('page-change', { pageSize: value, currentPage: this.tablePage.pageNum })
    },
    handleCurrentChange (value) {
      this.$emit('page-change', { pageSize: this.tablePage.pageSize, currentPage: value })
    },

    // 查看当前行是否展示
    isExpandByRow (...params) {
      return this.$refs.xTable.setAllTreeExpand(...params)
    },
    isActiveByRow (...params) {
      return this.$refs.xTable.isActiveByRow(...params)
    },
    clearActived (...params) {
      return this.$refs.xTable.clearActived(...params)
    },
    revertData (...params) {
      return this.$refs.xTable.revertData(...params)
    },
    setActiveRow (...params) {
      return this.$refs.xTable.setActiveRow(...params)
    },
    openPrint (...params) {
      return this.$refs.xTable.openPrint(...params)
    },
    getTreeExpandRecords (...params) {
      return this.$refs.xTable.getTreeExpandRecords(...params)
    },
    setTreeExpand (...params) {
      return this.$refs.xTable.setTreeExpand(...params)
    }
  }
}
</script>
<style scoped lang="scss">
.mx-10 {
  margin: 0 10px;
}
.toolbar-wrapper {
  padding: 6px 25px;
}
.pager-wrapper {
  padding: 15px 25px;
}
::v-deep {
  /* 字体颜色 */
  .vxe-table, .vxe-header--column {
    color: #000000D9!important;
  }
  /* 表头拖拽的竖线 */
  // .vxe-header--column .vxe-resizable.is--line:before {
  //   background-color: #f0f0f0!important;
  // }
  /* 行的上线边框线 */
  .vxe-table--render-default.border--default .vxe-body--column, .vxe-table--render-default.border--default .vxe-footer--column, .vxe-table--render-default.border--default .vxe-header--column, .vxe-table--render-default.border--inner .vxe-body--column, .vxe-table--render-default.border--inner .vxe-footer--column, .vxe-table--render-default.border--inner .vxe-header--column {
    background-image: linear-gradient(#f0f0f0,#f0f0f0)!important;
  }
  /* 表头下方的边框线 */
  .vxe-table .vxe-table--header-wrapper .vxe-table--header-border-line {
    border-bottom: 1px solid #f0f0f0!important;
  }
  /* 表格默认的展开按钮 */
  .vxe-table--render-default .vxe-table--expanded .vxe-table--expand-btn {
    opacity: .8;
  }
  /* 外边框 */
  .vxe-table--render-default.border--default .vxe-table--border-line{
    border: 1px solid #fff!important;
  }
  // border="full" 或 border
  .vxe-table--render-default.border--full .vxe-body--column, .vxe-table--render-default.border--full .vxe-footer--column, .vxe-table--render-default.border--full .vxe-header--column {
    background-image: linear-gradient(#f0f0f0, #f0f0f0),linear-gradient(#f0f0f0,#f0f0f0)!important;
  }
  .vxe-table--render-default .vxe-table--border-line {
    border: 1px solid #f0f0f0;
  }

  .vxe-header--column .vxe-resizable.is--line:after {
    width: 1px!important;
  }

  // el-scrollbar__bar 样式加宽一点
  .el-scrollbar__bar {
    height: 10px!important;
  }
}
</style>
