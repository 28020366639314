<!-- 页面布局组件 -->
<template>
  <div class="layout">
    <!-- 头部导航栏 -->
    <header-bar v-if="$route.query.simple != 1"/>

    <div class="app-main flex-1 flex flex-nowrap" :style="contentStyle">
      <!-- 侧边栏 -->
      <side-bar v-if="isShowNav"/>

      <div class="page-container flex-1">
        <!-- 面包屑 -->
        <breadcrumb v-if="isShowNav"/>

        <!-- 页面容器 -->
        <transition name="fade-transform" mode="out-in" :duration="300">
          <slot>
            <router-view/>
          </slot>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderBar from './components/HeaderBar'
import SideBar from './components/SideBar'
import Breadcrumb from './components/Breadcrumb'
export default {
  computed: {
    // 是否展示导航部分
    // 从国家平台来的需要隐藏侧边栏、导航栏、面包屑
    isShowNav () {
      return this.$route.query.simple != 1 && this.routes.length > 0
    },
    contentStyle () {
      return {
        height: this.$route.query.simple != 1 ? 'calc(100% - 56px)' : '100%'
      }
    },
    routes () {
      const activePerm = this.$store.state.account.perms.find(item => {
        return item.activePaths && item.activePaths.includes(this.$route.fullPath)
      })
      if (activePerm && activePerm.children) {
        return activePerm.children
      } else {
        return []
      }
    }
  },
  components: {
    HeaderBar,
    Breadcrumb,
    SideBar
  }
}
</script>

<style lang="scss" scoped>
.layout {
  height: 100%;
  background: #f0f2f5;

  .app-main {
    width: 100%;

    .page-container {
      position: relative;
      overflow: auto;
      color: #303133;
    }
  }
}
</style>
