import Vue from 'vue'
import http from '@/utils/http'
import store from '@/store'
import route from '@/router'

const mixin = {
  methods: {
    // 处理下载流媒体路径
    getMediaUrl (params) {
      if (!params) return ''
      return http.baseUrl + '/basefun/fdfscs/downloadOne?nowRelatUrl=' + params
    },

    hasAuth (value) {
      const perms = store.state.account.perms
      const fullPath = route.app.$route.fullPath
      const taget = XEUtils.findTree(perms, (item) => item.permType == 1 && fullPath.includes(item.accePath))
      if (taget) {
        // 权限列表为空，说明一个权限没有，所以当然页下的按钮权限全部隐藏
        if (!taget.item.children || taget.item.children.length <= 0) {
          return false
          // 权限列表不为空 且 不包含这个权限；说明没有配这个权限，隐藏
        } else if (!taget.item.children.some(sub => sub.permCode == value)) {
          return false
        } else {
          return true
        }
        // 没有找到这个菜单
      } else {
        return false
      }
    }
  }
}
Vue.mixin(mixin)
export default mixin
