<template>
  <div class="header-bar">
    <div v-if="$route.query.simple!=1 && $route.meta.subMenu!==false" ref="headerTabs" class="left-tabs">
      <div
        ref="tabItem"
        class="tab-item"
        :class="{active: $route.path.includes(item.accePath)}"
        v-for="item in routes"
        :key="item.permId"
        @click="selectedTab(item)"
      >{{item.permName}}</div>
    </div>
    <span class="header-title">{{$route.meta.title || dataViewTitle}}</span>
    <div class="right-time">{{currTimeFormat}}</div>
  </div>
</template>

<script>
import XEUtils from 'xe-utils'
export default {
  data () {
    return {
      currTime: Date.now(),
      tabActiveId: 1
    }
  },
  computed: {
    currTimeFormat () {
      return XEUtils.toDateString(this.currTime, 'yyyy-MM-dd HH:mm:ss')
    },
    // 获取subMenu
    routes () {
      console.log(this.$store.state.account.perms)
      const activePerm = this.$store.state.account.perms.find(item => {
        return item.activePaths && item.activePaths.includes(this.$route.path)
      })
      if (activePerm && activePerm.children) {
        return activePerm.children
      } else {
        return []
      }
    },
    dataViewTitle () {
      const currPath = this.$route.path
      console.log(currPath, this.routes, 'currPath')
      const target = this.routes.find(item => currPath.includes(item.accePath)) || { permName: '' }
      return target.permName
    }
  },
  created () {
    // 右侧时钟
    let timer = setInterval(() => {
      this.currTime = Date.now()
    }, 1000)
    this.$once('hook:beforeDestroy', () => {
      clearInterval(timer)
      timer = null
    })
  },
  mounted () {
    // 给tab布局，并监听resize调整布局
    this.layoutTabs()
    window.addEventListener('resize', this.layoutTabs)
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('resize', this.layoutTabs)
    })
  },
  methods: {
    selectedTab (route) {
      this.$router.push(route.accePath)
    },

    /**
     * 动态计算若有被中间标题遮挡的菜单item，要对tabs重新布局
     */
    layoutTabs () {
      const tabItemWidth = 115 // 每个item的宽度
      const fixWidth = 420 // 头部标题所占宽度(左侧比右侧窄，后面稍作调整)
      let currPos = 0 // 起始位置

      // 获取header中tabs高度
      const outterEL = this.$refs.headerTabs
      if (!outterEL) return

      const outterWidth = outterEL.clientWidth
      const leftWidth = (outterWidth - fixWidth) / 2
      // 遍历所有的item，计算并移动到新的位置
      if (this.$refs.tabItem) {
        this.$refs.tabItem.forEach((item, index) => {
          const itemPos = currPos + tabItemWidth
          let targetPos = 0
          if (itemPos < leftWidth) {
            targetPos = currPos
          } else {
            // 下一个item若被遮挡，当前的item到标题的距离要给到下一个item
            const diff = currPos < leftWidth ? leftWidth - currPos : 0
            currPos += diff
            targetPos = currPos + fixWidth - 40 // (调整左右不对称)
          }

          item.style.transition = 'transform .' + (index + 4) + 's ease'
          item.style.right = 'auto'
          item.style.transform = `translateX(${targetPos}px)`

          // 移动
          currPos += tabItemWidth
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header-bar {
  height: 54px;
  background: #000000 url('~@/assets/imgs/common/dlv-header.png') no-repeat center;
  position: relative;
  user-select: none;

  .header-title {
    position: absolute;
    left: calc(50% - 15px);
    top: calc(50% - 3px);
    transform: translate(-50%, -50%);
    font-size: 25px;
    @include text-light(#00FFFF);
  }

  .right-time {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    @include text-light(#00FFFF);
    font-family: DIN-Regular;
  }

  .left-tabs {
    padding-top: 14px;
    padding-left: 15px;
    position: relative;

    .tab-item {
      position: absolute;
      left: 20px;
      top: 14px;
      transform: translateX(100vw);
      width: 115px;
      line-height: 43px;
      text-align: center;
      font-size: 14px;
      background: url('~@/assets/imgs/common/tab-bg-normal.png') no-repeat center;
      cursor: pointer;
      @include text-light(#00FFFF);

      &.active,
      &:hover {
        background: url('~@/assets/imgs/common/tab-bg-active.png') no-repeat center;
        @include text-light(#FCCC00);
      }

    }
  }
}
</style>
