import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

// 加载 ./store 目录下所有的js模块
const files = require.context('.', false, /\.js$/)
const modules = {}
files.keys().forEach(key => {
  if (key === './index.js') return
  modules[key.replace(/(\.\/|\.js)/g, '')] = files(key).default
})

export default new Vuex.Store({
  /**
   * strict 在严格模式下，无论何时发生了状态变更且不是由 mutation 函数引起的，将会抛出错误。
   * 这能保证所有的状态变更都能被调试工具跟踪到。
   */
  strict: process.NODE_ENV !== 'production',
  modules: { ...modules },
  plugins: [
    createPersistedState({
      key: 'wazert-supervise',
      // storage: window.sessionStorage, // 默认值是 localStorage
      reducer ({ settings, account: { loginInfo } }) {
        return {
          settings,
          account: { loginInfo }
        }
      }
    })
  ]
})
