/**
 * 这里的mock并不是集合devServer.before来使用
 * 而是通过，调用方法 =》 延时模拟，可以实现热更新
 * ===
 * 在devServer.before钩子触发，并不会触发热更新，
 * 因为webpack监听的是所有被入口文件引用的文件
 */

// 响应体
const responseBody = {
  msg: '',
  data: null,
  code: 0
}

// 生成响应体
export const builder = (data, msg, code = 0, headers = {}) => {
  responseBody.data = data
  if (msg !== undefined && msg !== null) {
    responseBody.msg = msg
  }
  if (code !== undefined && code !== 0) {
    responseBody.code = code
    responseBody._status = code
  }
  if (headers !== null && typeof headers === 'object' && Object.keys(headers).length > 0) {
    responseBody._headers = headers
  }
  // responseBody.timestamp = new Date().getTime()
  return responseBody
}

// 获取字符串中的query
export const getQueryParameters = (options) => {
  const url = options.url
  const search = url.split('?')[1]
  if (!search) {
    return {}
  }
  return JSON.parse('{"' + decodeURIComponent(search)
    .replace(/"/g, '\\"')
    .replace(/&/g, '","')
    .replace(/=/g, '":"') + '"}')
}

// 获取请求体
export const getBody = (options) => {
  return options.body && JSON.parse(options.body)
}
