import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// mock
// WARNING: `mockjs` NOT SUPPORT `IE` PLEASE DO NOT USE IN `production` ENV.
import './mock'
// 初始化样式，依赖的样式都从index.css引入
import '@/assets/styles/index.scss'
import 'nprogress/nprogress.css' // progress bar style
// 注册全局指令
import '@/utils/initGlobalDirectives'
// 注册全局组件
import '@/utils/initGlobalComps'
// 注册全局混入
import '@/utils/initGlobalMixins'
// 初始化权限
import '@/utils/initAuth'
// 初始化websocket
import '@/utils/websocket'
// 注册element组件
import '@/utils/initElementUI'
// 引入 VXETable
import 'vxe-table/lib/style.css'
import VXETable from 'vxe-table'
import VXETablePluginExportXLSX from 'vxe-table-plugin-export-xlsx'
import XEUtils from 'xe-utils'
import '@/utils/initMicroApp'

VXETable.use(VXETablePluginExportXLSX)
Vue.use(VXETable)

// 一些比较常用的库可以，挂载到window上
window.XEUtils = XEUtils
Vue.prototype.$XPrint = VXETable.print

// 生产环境关闭提示
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
