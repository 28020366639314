// 项目中不需要响应式的配置
export function getSystemName () {
  const systemNames = {
    'hzdsj.wazert.com': '湖州市散装水泥、预拌混凝土和预拌砂浆大数据平台', // 湖州散办
    'hzjgz.wazert.com': '杭州市混凝土信息管理平台', // 杭州建管站(管理端)
    '115.227.32.89': '杭州市混凝土信息管理平台', // 政务云平台
    '123.60.136.46': '杭州市混凝土信息管理平台', // 杭州建管站(管理端)
    'beton.wazert.com': '杭州市商品混凝土企业信息管理系统', // 杭州建管站(企业端)
    // 本地
    'jgzaio.wazert.com': '建材统一平台',
    'hj.wazert.com': '宏基供货商供料平台',
    localhost: '建材统一平台',
    '172.16.0.73': '建材统一平台',
    'gjhntpt.wazert.com': '国家混凝土数字化管理平台'
  }
  const hostname = location.hostname
  const targetKey = Object.keys(systemNames).find(key => key.includes(hostname))
  return targetKey ? systemNames[targetKey] : ''
}
