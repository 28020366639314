import http from '@/utils/http'

// 下载单个文件
export function downloadOne (p) {
  return http.get('/basefun/fdfscs/downloadOne', p)
}
// 根据经纬度获取地址
export function getAddress (p) {
  return http.post('/basefun/mapcs/reGeocoding', p)
}
// 根据地址获取经纬度
export function getPlaces (p) {
  return http.post('basefun/mapcs/place2', p)
}

// 分级获取地区列表
export function getAreaByType (p) {
  return http.get('/unibu/base/getAreaByType', p)
}
// 获取省份
export function gainProvin (p) {
  return http.post('/unibu/monitor/vehi/selectAll', p)
}
// 获取市级
export function gainCity (p) {
  return http.post('/basefun/regics/gainCity', p)
}
// 获取区县
export function gainCountr (p) {
  return http.post('/basefun/regics/gainCountr', p)
}
// 获取地区
export function gainRegi (p, cancelFlag) {
  return http.post('/basefun/regics/gainRegi', p, cancelFlag)
}
// 企业列表
export function gianComplist (p) {
  return http.post('/mortconc/compcs/list', p)
}
// 关联单位多条
export function gianRelacsMore (p) {
  return http.post('/mortconc/relacs/more', p)
}
// 人员多条
export function gianPerscsMore (p) {
  return http.post('/mortconc/perscs/more', p)
}
// 账号选择企业
export function selectedComp (p) {
  return http.post('/mortconc/logincs/focusEntity', p)
}
// 机构列表(不分页)
export function getOrgas (p) {
  return http.post('/mortconc/orgacs/more', p)
}
// 企业列表(不分页)
export function getComps (p = {}) {
  return http.post('/mortconc/compcs/more', p)
}
// 工程列表(不分页)
export function getProjects (p) {
  return http.post('/mortconc/projcs/more', p)
}
// 企业列表
// 就是如果是企业账号进来的话 不会展示所有企业的
// 管理账号就展示管理账号下所有能管理的企业数据
export function getCompInfoByArea (p) {
  return http.get('/material/topi/getCompInfoByArea', p)
}
// 平台列表(数据平台)不分页
export function getPlatecs (p) {
  return http.post('/basefun/platecs/more', p)
}

// 无条件获取客户信息（新）
export function getRelaList (p) {
  return http.post('/unibu/rela/getRelaList', p)
}

// 存储json数据
export function setJsonData (p) {
  return http.post('/mortconc/logincs/boundMenu', p)
}

// 获取json数据
export function getJsonData (p) {
  return http.post('/mortconc/logincs/getMenu', p)
}

// 根据客户端IP对应所在地查询未来城市天气预报
export function ipToFutureRecord (p) {
  return http.post('/basefun/wxfcs/ipToFutureRecord', p, false)
}

// 根据客户端IP对应所在地查询今日城市生活指数预报
export function ipToTodayLivingRecord (p) {
  return http.post('/basefun/wxfcs/ipToTodayLivingRecord', p, false)
}

// 根据客户端IP对应所在地查询今日城市天气预报
export function ipToTodayRecord (p) {
  return http.post('/basefun/wxfcs/ipToTodayRecord', p, false)
}
