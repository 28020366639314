<template>
  <div :style="rootStyle">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    padding: {
      default: 16
    },
    // fullframe
    type: {
      default: ''
    }
  },
  computed: {
    rootStyle () {
      return {
        padding: this.padding + 'px',
        // height: $route.query.simple != 1 ? 'calc(100% - 56px)' : '100%'
        height: this.type == 'fullframe' ? (this.$route.query.simple != 1 ? 'calc(100% - 56px)' : '100%') : undefined
      }
    }
  }
}
</script>

<style>

</style>
