import variables from '@/assets/styles/element-variables.scss'

const state = {
  theme: variables.theme, // 主题色
  isCollapse: false // 控制侧边菜单栏折叠
}

const mutations = {
  CHANGE_SETTING: (state, { key, value }) => {
    if (Object.prototype.hasOwnProperty.call(state, key)) {
      state[key] = value
    }
  }
}

const actions = {
  changeSetting ({ commit }, data) {
    commit('CHANGE_SETTING', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
