import Vue from 'vue'

// 自适应组件
import ResizeWrap from '@/components/ResizeWrap'

// 表格默认属性封装
import WzTable from '@/components/WzTable'
import BaseTable from '@/components/common/BaseTable'
import BaseSearchForm from '@/components/common/BaseSearchForm'
import BaseForm from '@/components/common/BaseForm'
import PageContainer from '@/components/common/PageContainer'
import VirtualSelect from '@/components/common/VirtualSelect'
import DataSelect from '@/components/Dictionary/DataSelect'
import FormModule from '@/components/Form/FormModule'
Vue.component('ResizeWrap', ResizeWrap)
Vue.component('WzTable', WzTable)
Vue.component('BaseTable', BaseTable)
Vue.component('BaseSearchForm', BaseSearchForm)
Vue.component('PageContainer', PageContainer)
Vue.component('BaseForm', BaseForm)
Vue.component('VirtualSelect', VirtualSelect)
Vue.component('DataSelect', DataSelect)
Vue.component('FormModule', FormModule)
