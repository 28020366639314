import http from '@/utils/http'
import localforage from 'localforage'

// 获取车相关的树
export async function getBusTree (p, cancel = true, cache = true) {
  const keys = ['getBusLocTree', p.key, p.parentKey].filter(item => item)
  const key = keys.join('_')

  // 获取缓存
  const cacheRes = await localforage.getItem(key)

  if (cacheRes && cache) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(JSON.parse(JSON.stringify(cacheRes)))
      }, 500)
    })
  } else {
    return new Promise((resolve, reject) => {
      http.post('/unibu/monitor/getBusLocTree0', p, cancel).then((res) => {
        if (res.code == 200) {
          // 存储缓存
          localforage.setItem(key, res).finally(() => {
            resolve(JSON.parse(JSON.stringify(res)))
          })
        } else {
          reject(res)
        }
      })
    })
  }
}

// 获取车辆列表
export async function getBusList (p, flag = true, cache = true) {
  const key = 'selectAll'
  // 获取缓存
  const cacheRes = await localforage.getItem(key)
  if (cacheRes && cache) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(JSON.parse(JSON.stringify(cacheRes)))
      }, 500)
    })
  } else {
    return new Promise((resolve, reject) => {
      http.post('/unibu/monitor/vehi/selectAll', p, flag).then((res) => {
        if (res.code == 200) {
          // 存储缓存
          localforage.setItem(key, res).finally(() => {
            resolve(JSON.parse(JSON.stringify(res)))
          })
        } else {
          reject(res)
        }
      })
    })
  }
}
