<template>
  <!-- 菜单列表 -->
  <el-menu
    class="side-bar el-menu-vertical-demo"
    :default-active="activeMenu"
    :collapse="$store.state.settings.isCollapse"
    :unique-opened="false"
    :collapse-transition="false"
    :background-color="$store.state.settings.theme"
    text-color="#fff"
    active-text-color="#fff"
    mode="vertical"
  >
    <sidebar-item v-for="route in routes" :key="route.permId" :item="route"/>
  </el-menu>
</template>

<script>
import SidebarItem from './SideBarItem'

export default {
  computed: {
    // 获取subMenu
    routes () {
      const activePerm = this.$store.state.account.perms.find(item => {
        return item.activePaths && item.activePaths.includes(this.$route.fullPath)
      })
      if (activePerm && activePerm.children) {
        return activePerm.children
      } else {
        return []
      }
    },

    activeMenu () {
      return this.$route.path
    }
  },
  components: {
    SidebarItem
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-submenu__title .el-submenu__icon-arrow {
  color: rgba(255,255,255,.9);
}

.side-bar {
  min-height: calc(100vh - 56px);
  transition: background-color 0.3s;

  // 菜单不折叠的时候要给一个宽度
  &:not(.el-menu--collapse) {
    width: 210px;
  }

  // 修复菜单折叠时，显示菜单名称 和 箭头
  &:not(.el-menu--collapse) ::v-deep {
    .el-submenu__title {
      span, .el-submenu__icon-arrow {
        display: inline-block;
      }
    }
  }

  ::v-deep {
    .el-submenu__title {
      span, .el-submenu__icon-arrow {
        display: none;
      }
    }
  }
}

::v-deep  {
  .el-menu-item,
  .el-submenu__title,
  .el-submenu .el-menu-item {
    color: rgba(255,255,255,.9) !important;
    font-size: 14px;
    box-sizing: border-box;
    height: 56px;
    line-height: 56px;
    &:hover,
    &:focus {
      background-image: url("~@/assets/imgs/common/side-menu-bg.png");
      background-color: transparent !important;
    }
  }
  .el-submenu .el-menu-item {
    padding-left: 56px!important;
    height: 50px;
    line-height: 50px;
  }
  .el-menu-item.is-active,
  .el-submenu .el-menu-item.is-active {
    background-image: url("~@/assets/imgs/common/side-menu-bg.png");
  }
  .el-submenu__icon-arrow.el-icon-arrow-down {
    margin-top: -4px;
  }
  .sub-el-icon {
    margin-top: -2px;
  }
}

</style>
