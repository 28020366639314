<template>
  <!-- permType 目录: 3; 菜单: 1; 功能: 2; -->
  <div>
    <!-- 菜单 -->
    <template v-if="item.permType==1">
      <router-link :to="item.accePath">
        <el-menu-item :index="item.accePath" :class="{'submenu-title-noDropdown':!isNest}">
          <item class="flex-row" v-if="!!item.setDefaultUrl" icon="iconfont icon-wenjianjia" :title="item.permName" />
          <item class="flex-row" v-else :icon="getMediaUrl(item.permPicURL0)" :title="item.permName" />
        </el-menu-item>
      </router-link>
    </template>

    <!-- 目录 -->
    <el-submenu v-if="item.permType==3" ref="subMenu" :index="item.accePath" popper-append-to-body>
      <template slot="title">
        <item class="flex-row" v-if="!!item.setDefaultUrl" icon="iconfont icon-wenjianjia" :title="item.permName" />
        <item class="flex-row" v-else :icon="getMediaUrl(item.permPicURL0)" :title="item.permName" />
      </template>
      <sidebar-item
        v-for="child in item.children"
        :key="child.permId"
        :is-nest="true"
        :item="child"
        class="nest-menu"
      />
    </el-submenu>
  </div>
</template>

<script>
import path from 'path'
import Item from './Item'

export default {
  name: 'SidebarItem',
  components: { Item },
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  data () {
    // To fix https://github.com/PanJiaChen/vue-admin-template/issues/237
    // TODO: refactor with render function
    this.onlyOneChild = null
    return {}
  },
  methods: {

    isExternal (path) {
      return /^(https?:|mailto:|tel:)/.test(path)
    },

    hasOneShowingChild (children = [], parent) {
      const showingChildren = children.filter(item => {
        if (item.hidden) {
          return false
        } else {
          // Temp set(will be used if only has one showing child)
          this.onlyOneChild = item
          return true
        }
      })

      // When there is only one child router, the child router is displayed by default
      if (showingChildren.length === 1) {
        return true
      }

      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        this.onlyOneChild = { ...parent, path: '', noShowingChildren: true }
        return true
      }

      return false
    },
    resolvePath (routePath) {
      if (this.isExternal(routePath)) {
        return routePath
      }
      if (this.isExternal(this.basePath)) {
        return this.basePath
      }
      return path.resolve(this.basePath, routePath)
    }
  }
}
</script>
