<script>
// 记录submitForm，比对
class FormUtil {
  constructor (initFormData) {
    this.initFormData = XEUtils.clone(initFormData, true)
  }

  compareToForm (submitForm) {
    return XEUtils.isEqualWith(this.initFormData, XEUtils.clone(submitForm, true))
  }
}

export default {
  render () {
    const attrs = this.$attrs
    const defaultSlots = this.$slots.default
    const listeners = this.$listeners
    return (
      <el-form {...{ attrs, on: listeners }} ref="baseForm" label-width="130px" >
        {defaultSlots}
      </el-form>
    )
  },
  data () {
    return {
      initFormData: null,
      visible: false,
      loading: false,
      isLoad: false,
      submitForm: {
        id: '',
        dictCode: '', // 字典编码（同一枚举编码相同）
        dictLevel: '', // 字典所在层级
        dictName: '', // 字典前端展示名称
        dictParentValue: '', // 字典数据库存储父级值（顶级字典的父级值为-1）
        dictValue: '' // 字典数据库存储值（最顶级默认为0，代表所有）
      },
      formUtil: null,
      rules: {
        dictCode: { required: true, message: '请输入字典编码' }
      }
    }
  },
  methods: {
    updateVisible () {
      this.$emit('update:visible', false)
    },

    // 验证字典，比较字段，重置字段，关闭弹窗，刷新列表事件
    submit () {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const submitForm = { ...this.submitForm }
          if (this.formUtil.compareToForm(submitForm)) {
            this.$notify.success({ title: '成功', message: '保存成功：无内容变更！' })
            return true
          } else {
            this.loading = true
          }
          this.updateVisible()
        } else {
          return false
        }
      })
    },
    open (initFormData) {
      this.visible = true
      this.initFormData = initFormData
      if (initFormData) {
        // 新增下级
        if (initFormData.isAddSub) {
          this.submitForm.dictParentValue = initFormData.dictValue
          this.submitForm.dictCode = initFormData.dictCode
        // 编辑
        } else {
          Object.keys(this.submitForm).forEach((key) => {
            const value = initFormData[key]
            if (value) this.submitForm[key] = value
          })
        }
      }
      // FormUtil
      this.formUtil = new FormUtil(this.submitForm)
    },

    handlerClose () {
      this.initFormData = null
      this.formUtil = null
      const sourceForm = this.$options.data(this).submitForm
      this.submitForm = Object.assign({}, this.submitForm, sourceForm)
      this.$refs.form.resetFields()
    },

    setSourceFields (submitForm) {
      this.formUtil = new FormUtil(submitForm)
    },

    validate (...params) {
      return this.$refs.baseForm.validate(...params)
    },
    resetFields (...params) {
      return this.$refs.baseForm.resetFields(...params)
    }
  }
}
</script>
