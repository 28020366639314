<template>
  <vxe-pulldown ref="virtualSelect" @hide-panel="blurEvent">
    <template #default>
      <el-input :key="key1" :class="$attrs.class"  :disabled="$attrs.disabled" v-show="!visible" v-model="formatValue" @focus="focusEvent" :placeholder="$attrs.placeholder||'请输入'" clearable :suffix-icon="visible?'el-icon-arrow-up':'el-icon-arrow-down'"></el-input>
      <el-input ref="input2" :key="key2" :disabled="$attrs.disabled" :class="$attrs.class" v-show="visible" v-model="keyword" :placeholder="formatValue|| $attrs.placeholder||'请输入'"  :suffix-icon="visible?'el-icon-arrow-up':'el-icon-arrow-down'" @input="keyupEvent"></el-input>
    </template>
    <template #dropdown>
      <span v-if="list.length===0"></span>
      <vxe-list v-else height="200" class="my-dropdown2" :data="list" :scroll-y="{gt:0,oSize:50}" :auto-resize="true">
        <template #default="{ items }">
          <div class="el-select-dropdown__item" v-for="item in items" :key="item[valueName]" @click="selectEvent(item)">
            <i class="fa fa-envelope-o"></i>
            <span style="display: inline-block;" :style="{width: maxLength*14+'px'}">{{ item[labelName] }}</span>
          </div>
        </template>
      </vxe-list>
    </template>
  </vxe-pulldown>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default () {
        return []
      }
    },
    value: {
      default: ''
    },
    option: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  computed: {
    formatValue: {
      set (value) {
        this.$emit('input', value)
      },
      get () {
        return this.data.find((item) => item[this.valueName] == this.value)?.[this.labelName]
      }
    },
    valueName () {
      return this.option.value || 'value'
    },
    labelName () {
      return this.option.label || 'label'
    }
  },

  data () {
    return {
      key1: Date.now() + '1',
      key2: Date.now() + '2',
      list: [],
      keyword: '',
      visible: false,
      maxLength: 0
    }
  },
  watch: {
    data: {
      immediate: true,
      handler (newData) {
        this.list = newData
      }
    },
    list (newValue) {
      newValue.forEach(item => {
        if (item[this.labelName]?.length && item[this.labelName]?.length > this.maxLength) {
          this.maxLength = item[this.labelName]?.length
        }
      })
    }
  },
  methods: {
    focusEvent () {
      this.visible = true
      setTimeout(() => {
        this.$refs.input2.focus()
        this.$refs.virtualSelect.showPanel()
      }, 14)
    },
    blurEvent () {
      this.keyword = ''
      this.visible = false
      this.$refs.virtualSelect.hidePanel().then(() => {
        this.list = this.data
      })
    },
    keyupEvent (value) {
      const keyword = this.keyword = value
      this.list = keyword ? this.data.filter(item => {
        return item[this.labelName].indexOf(keyword) > -1
      }) : this.data
    },
    selectEvent (item) {
      this.keyword = ''
      this.visible = false
      this.$emit('input', item[this.valueName])
      this.$refs.virtualSelect.hidePanel().then(() => {
        this.list = this.data
      })
    }
  }
}
</script>

<style>
.my-dropdown2 {
  border: 1px solid #d7dae2;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
</style>
