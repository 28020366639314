import Vue from 'vue'
import XEUtils from 'xe-utils'
import store from '@/store'
import route from '@/router'
import clipboard from '@/utils/clipboard'
import Scroller from '@/utils/scroller'

// v-dialogDrag: 弹窗拖拽
Vue.directive('dialogDrag', {
  bind (el, binding, vnode, oldVnode) {
    const dialogHeaderEl = el.querySelector('.el-dialog__header')
    const dragDom = el.querySelector('.el-dialog')
    dialogHeaderEl.style.cursor = 'move'

    // 获取原有属性 ie dom元素.currentStyle 火狐谷歌 window.getComputedStyle(dom元素, null);
    const sty = dragDom.currentStyle || window.getComputedStyle(dragDom, null)

    dialogHeaderEl.onmousedown = (e) => {
      // 鼠标按下，计算当前元素距离可视区的距离
      const disX = e.clientX - dialogHeaderEl.offsetLeft
      const disY = e.clientY - dialogHeaderEl.offsetTop

      // 获取到的值带px 正则匹配替换
      let styL, styT

      // 注意在ie中 第一次获取到的值为组件自带50% 移动之后赋值为px
      if (sty.left.includes('%')) {
        styL = +document.body.clientWidth * (+sty.left.replace(/%/g, '') / 100)
        styT = +document.body.clientHeight * (+sty.top.replace(/%/g, '') / 100)
      } else {
        styL = +sty.left.replace(/\px/g, '')
        styT = +sty.top.replace(/\px/g, '')
      }

      document.onmousemove = function (e) {
        // 通过事件委托，计算移动的距离
        const l = e.clientX - disX
        const t = e.clientY - disY

        // 移动当前元素
        dragDom.style.left = `${l + styL}px`
        dragDom.style.top = `${t + styT}px`

        // 将此时的位置传出去
        // binding.value({x:e.pageX,y:e.pageY})
      }

      document.onmouseup = function (e) {
        document.onmousemove = null
        document.onmouseup = null
      }
    }
  }
})

// 权限指令
Vue.directive('auth', {
  inserted (el, { value }) {
    const perms = store.state.account.perms
    const fullPath = route.app.$route.fullPath
    const taget = XEUtils.findTree(perms, (item) => item.permType == 1 && item.accePath && fullPath.includes(item.accePath))
    if (taget) {
      // 权限列表为空，说明一个权限没有，所以当然页下的按钮权限全部隐藏
      if (!taget.item.children || taget.item.children.length <= 0) {
        el.style.display = 'none'

        // 权限列表不为空 且 不包含这个权限；说明没有配这个权限，隐藏
      } else if (!taget.item.children.some(sub => sub.permCode == value)) {
        el.style.display = 'none'
      }

      // 没有找到这个菜单
    } else {
      el.style.display = 'none'
    }
  }
})

// 复制粘贴指令
Vue.directive('clipboard', clipboard)

// 动画, 默认是jello
Vue.directive('animate', {
  bind (el) {
    el.classList.add('animated')
  },
  inserted (el, { value = 'jello' }) {
    el.addEventListener('click', () => {
      if (!el.classList.contains(value)) {
        el.classList.add(value)
        setTimeout(() => {
          el.classList.remove(value)
        }, 1000)
      }
    })
  }
})

Vue.directive('horizontalScroll', {
  inserted (el, binding) {
    const { value = 'hover' } = binding
    const tableBodyWrapper = el.querySelector('.vxe-table--body-wrapper.body--wrapper')
    const scroller = new Scroller(tableBodyWrapper, value)

    el.appendChild(scroller.dom)
    el.horizontalScroll = scroller

    // if (value === 'hover') {
    //   el.addEventListener('mouseover', scroller.showBar.bind(scroller))
    //   el.addEventListener('mouseleave', scroller.hideBar.bind(scroller))
    // } else {
    scroller.showBar()
    // }
  },
  unbind (el) {
    el.horizontalScroll.destory()
  }
})
