import XEUtils from 'xe-utils'
import localforage from 'localforage'
import { login, getUserInfo, refreshToken } from '@/apis/login'
import { ipToTodayRecord, ipToFutureRecord, gianComplist } from '@/apis/common'

// state默认值
const getDefaultState = () => {
  return {
    perms: [], // 权限列表 树结构
    comps: [], // 企业列表
    loginInfo: {
      token: '',
      tokenType: '',
      expires: 0,
      loginName: '',
      refreshToken: ''
    },
    userInfo: { // 用户信息
      persId: null, // 人员流水号
      accoId: '', // 账户流水号
      accoPId: '', // 父类-上级账户流水号(顶层默认0)
      compId: '', // 企业id
      orgaId: null, // 机构id
      relaId: null, // 关联单位流水号
      userName: '', // 用户名称
      loginName: '', // 登录账号
      pwd: '', // 登录密码
      prevPwd: '', // 上次登录密码
      cardID: '', // 身份证号码
      phone: '', // 手机号
      eAstrDatTim: '', // 限制截止日期时间
      gsessionID: '', // 网泽培优-token
      isAstrict: 0, // 是否限制 0:无限制 1:已限制
      perms: [], // 权限列表
      roles: [], // 角色列表
      validDay: null, // token有效天数
      wxOpenid: null // 微信openId
    },
    todayWeather: null,
    futureWeather: [],
    storage: {}
  }
}
export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    CHANGE_STATE: (state, { key, value }) => {
      if (Object.prototype.hasOwnProperty.call(state, key)) {
        state[key] = value
      }
    },
    CHANGE_USERINFO (state, userInfo) {
      const props = { key: 'permId', parentKey: 'permPId', sortKey: 'levelOrder' }
      const perms = (JSON.parse(JSON.stringify(userInfo.perms))).sort((a, b) => a.levelOrder - b.levelOrder)
      const treeData = XEUtils.toArrayTree(perms, props)
      // 循环树的每一项，如果是目录且下面有子节点
      treeData.forEach(item => {
        if (item.permType == 3 && item.children && item.children.length > 0) {
          setPanrentPath(item)
        }
        item.children.forEach((subItem) => {
          if (!subItem.permPicURL0) {
            subItem.setDefaultUrl = true
          }
        })
      })
      state.userInfo = userInfo // 保存userInfo
      state.perms = treeData // 保存perms

      // 内部函数：处理父级path
      function setPanrentPath (parent, paths) {
        // 通过引用给目录设置 accePath，规则如下
        // 1. 所有的目录的路径都是目录下第一个子节点的路径
        // 2. 如果目录下第一个节点是菜单，该目录的路径就是该子节点的路径；
        // 3. 如果目录下第一个节点仍是目录，就先给该子目录设置路径，再取子目录的路径做为该目录的路径；
        // 第一级目录需要存储所有孙子节点的path
        paths = paths || (parent.activePaths = [])
        parent.children.forEach((item, index) => {
          // 保存孙子path
          if (item.permType == 1) {
            paths.push(item.accePath)
          }
          // 菜单
          if (item.permType == 1 && index === 0) {
            parent.accePath = item.accePath
          } else if (item.permType == 3 && index === 0) {
            setPanrentPath(item, paths)
            parent.accePath = item.accePath
          } else if (item.permType == 3) {
            setPanrentPath(item, paths)
          }
        })
      }
    },
    RESET_STATE: (state) => {
      Object.assign(state, getDefaultState())
    },
    // 重置用户信息
    RESET_USERINFO: (state) => {
      state.userInfo = getDefaultState().userInfo
      state.perms = getDefaultState().userInfo.perms
    }
  },
  actions: {
    // 登录，并 存储token到vuex中
    login ({ commit }, params) {
      return new Promise((resolve) => {
        login(params).then((res) => {
          const { code, data: value } = res
          if (code == 200) {
            commit('CHANGE_STATE', { key: 'loginInfo', value })
          }
          resolve(res)
        })
      })
    },

    // 清除 account vuex
    clearAcountInfo ({ commit }) {
      return new Promise(resolve => {
        commit('tableStorage/CHANGE_SETTING', { key: 'jsonData', value: '' }, { root: true })
        // 重置vuex
        commit('RESET_STATE')
        // 清空indexdb
        localforage.clear().finally(() => {
          resolve()
        })
      })
    },

    // 获取用户权限列表
    getUserInfo ({ commit }) {
      return new Promise((resolve) => {
        getUserInfo().then((res) => {
          const { data, code, msg } = res
          if (code == 200) {
            commit('CHANGE_USERINFO', data)
          } else {
            this.$message.error(msg)
          }
          resolve(res)
        })
      })
    },

    // 刷新登录信息和用户信息
    refreshToken ({ state, commit, dispatch }) {
      refreshToken({
        refreshToken: state.loginInfo.refreshToken
      }).then((res) => {
        if (res.code == 200) {
          // 刷新loginInfo
          const payload = { key: 'loginInfo', value: res.data }
          commit('CHANGE_STATE', payload)
          // 刷新userInfo，perms
          dispatch('getUserInfo')
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 当天天气预报
    ipToTodayRecord ({ commit }) {
      return new Promise((resolve) => {
        ipToTodayRecord().then((res) => {
          const { data, code } = res
          if (code == 200) {
            const payload = { key: 'todayWeather', value: data[0] }
            commit('CHANGE_STATE', payload)
          }
          resolve(res)
        })
      })
    },
    // 未来半个月天气预报
    ipToFutureRecord ({ commit }) {
      return new Promise((resolve) => {
        ipToFutureRecord().then((res) => {
          const { data, code } = res
          if (code == 200) {
            const payload = { key: 'futureWeather', value: XEUtils.slice(data, 1, 8) }
            commit('CHANGE_STATE', payload)
          }
          resolve(res)
        })
      })
    },

    // 获取当前token下的企业列表
    async getComps ({ commit }) {
      const res = await gianComplist({ pageNum: 1, pageSize: 2 })
      if (res.code == 200) {
        commit('CHANGE_STATE', { key: 'comps', value: res.data.rows })
      } else {
        this.$message.error(res.msg)
      }
      return res
    }
  }
}
