<template>
  <div>
    <p class="form-module-title fs-16 bold mb-15">{{title}}</p>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: ['title']
}
</script>

<style scoped lang="scss">
.form-module-title {
  color: #1f2f3d;
}
</style>
