import NProgress from 'nprogress'
import store from '@/store'
import router from '@/router'
import { cancelAll } from './http'
import { getSystemName } from '@/utils/settings'
import { getBusTree, getBusList } from '@/apis/monitor/carMonitor'
import { selectedComp } from '@/apis/common'

const whiteList = ['/login', '/error', '/noMenu'] // 没有token时，可以访问的路由白名单
let preLoadEl
router.beforeEach(async (to, from, next) => {
  // 取消上个页面未完成的接口，可在api方法中关闭清除功能
  cancelAll()

  // 从链接上获取token信息，对接别的平台
  receiveLinkToken(to)

  NProgress.start()

  const accountState = store.state.account // vuex of account
  const accountDispatch = store.dispatch
  const hasToken = !!accountState.loginInfo.token
  /**
   * - 有token:
   *    1. 访问的页面是login，就重定向到根路径;
   *    2. 访问是其他页面，查看是否有菜单，如果有就直接通过; 没有就处理菜单;
   *    3. 处理权限菜单 + 动态生成路由;
   *
   * - 没有token，如果当前页面不是在白名单whiteList中的页面，就跳转到login页面
   */
  if (hasToken) {
    if (to.path === '/login') {
      next({ path: '/' })
    } else {
      // 没有权限 要 获取权限
      if (accountState.perms.length <= 0) {
        // 企业失焦接口，调用之后才能获取到账号对应的省市区信息
        try {
          await selectedComp({ focusType: 2 })

          // 这里的接口是并行的
          await Promise.all([
            // 处理权限菜单
            accountDispatch('account/getUserInfo').then((res) => {
              if (res.data.perms.length == 0) {
                accountDispatch('account/clearAcountInfo')
              }
            }),
            accountDispatch('account/ipToTodayRecord'), accountDispatch('account/ipToFutureRecord')])

          // 预加载一些接口
          getBusTree({ key: 'COMPANY', parentKey: '' }, false)
          getBusTree({ key: 'DEV', parentKey: 'COMPANY' }, false)
          getBusList(undefined, false)
        } catch (e) {
          console.error('初始化接口异常')
        }
      }

      // 访问根路径就跳到第一个有效一级菜单的路径
      if (to.path === '/') {
        let currPath = ''
        accountState.perms.some(({ accePath }) => {
          currPath = accePath || ''
          return currPath
        })
        next(currPath || '/noMenu')
      } else {
        // 判断权限
        if (accountState.perms.length == 0) {
          next('/noMenu')
        } else if (
          whiteList.indexOf(to.path) !== -1 ||
          accountState.loginInfo.loginName == 'hzjw' ||
          accountState.userInfo.perms.find(item => (item.accePath == to.path || '/simple' + item.accePath == to.path))
        ) {
          next()
        } else {
          next('/error')
        }
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      next(`/login?redirect=${to.path}`)
    }
  }

  if (!preLoadEl) {
    preLoadEl = document.querySelector('#loading-wrapper')
    preLoadEl && (preLoadEl.style.display = 'none')
  }
})

router.afterEach((to) => {
  const routePath = to.fullPath
  const perms = store.state.account.perms // vuex of account
  const target = XEUtils.findTree(perms, (item) => item.accePath === routePath && item.permType == 1)
  document.title = target?.item?.permName || getSystemName()
  NProgress.done()
})

// 接受链接上传递的token，对接国家平台
// simple = 1， 隐藏导航栏 和 侧边栏
function receiveLinkToken (to) {
  const {
    token = '',
    refreshToken = '',
    tokenType = 'Bearer ',
    loginName = '',
    expires = 0
  } = to.query

  if (token) {
    store.commit('account/CHANGE_STATE', {
      key: 'loginInfo',
      value: {
        token,
        refreshToken,
        tokenType,
        loginName,
        expires
      }
    })
    // 删除query中与登录相关的参数
    Reflect.deleteProperty(to.query, 'token')
    Reflect.deleteProperty(to.query, 'refreshToken')
    Reflect.deleteProperty(to.query, 'tokenType')
    Reflect.deleteProperty(to.query, 'loginName')
    Reflect.deleteProperty(to.query, 'expires')
    router.replace({ path: to.path, query: to.query })
  }
}
