<script>
import { getDictionary } from '@/apis/authManage/dataDictionary'
export default {
  props: {
    dictCode: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      options: []
    }
  },
  created () {
    getDictionary({ dictCode: this.dictCode }).then((res) => {
      this.options = (res.data || []).filter(item => item.activity)
    })
  },
  render () {
    const attrs = this.$attrs
    const listeners = this.$listeners
    return (
      <el-select {...{ attrs, on: listeners }} clearable>
        {this.options.map(item => (
          <el-option key={item.dictValue} label={item.dictName} value={item.dictValue} />
        ))}
      </el-select>
    )
  }
}
</script>

<style>

</style>
