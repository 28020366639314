import axios from 'axios'
import { MessageBox } from 'element-ui'
import store from '@/store'

// 创建axios实例
const timeout = 80000
const service = axios.create({
  baseURL: location.origin,
  timeout: timeout // 设置接口超时时间
})

// 请求拦截，添加token
service.interceptors.request.use(
  (config) => {
    const { token, tokenType } = store.state.account.loginInfo
    const fullToken = tokenType + token
    if (token) config.headers.Authorization = fullToken
    return config
  }
)

/**
 * 响应拦截
 */
window.requestError = false // token过期多次报错，只弹一个报错弹窗
service.interceptors.response.use(
  (response) => {
    const res = response.data || {}

    // 清除登录态，重新登录，此后接口不管成功失败都不需要执行了
    if (res.code == 5001) {
      clearLoginState()
      return new Promise(() => {})
    }
    return res
  },

  // 报错处理：1. 取消请求(后续不需要继续执行)；2.请求超时；3.请求报错；
  (error) => {
    if (error.__CANCEL__) {
      console.warn('---取消无效请求---')
      return new Promise(() => {})
    } else if (error?.message?.includes('timeout')) {
      return Promise.resolve({
        msg: '请求超时，超时时间为' + timeout / 1000 + '秒'
      })
    } else {
      const res = error.response.data || { msg: '', code: '' }
      // 清除登录态，重新登录
      if (res.code == 5001) {
        clearLoginState()
      } else {
        return Promise.resolve({
          code: res.code,
          msg: res.code ? `请求失败，code：${res.code}，msg：${res.msg}。` : `请求失败，状态码：${error.response.status} ${error.response.statusText}`
        })
      }
    }
  }
)

export default service

// 清除登录态，重新登录
function clearLoginState () {
  if (!window.requestError) {
    window.requestError = true
    MessageBox.confirm('登录信息过期', '提示', {
      showClose: false,
      confirmButtonText: '重新登录',
      type: 'warning'
    }).then(() => {
      window.requestError = false
      store.dispatch('account/clearAcountInfo').then(() => {
        location.reload()
      })
    })
  }
}

// 报错弹窗
// function popErrMessage (msg) {
//   Message.error(msg)
// }
