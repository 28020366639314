<template>
  <!-- 数据视图布局 -->
  <div class="layout-box">
    <!-- 头部 -->
    <header-bar v-if="$route.query.simple!=1" />
    <data-view-header />
    <!-- content -->
    <router-view class="app-main-data-view" :style="$route.query.simple!=1?'height: calc(100vh - 56px - 54px);':'height: calc(100vh - 54px);'"></router-view>
  </div>
</template>

<script>
import HeaderBar from './components/HeaderBar'
import DataViewHeader from './components/DataViewHeader.vue'

export default {
  components: {
    HeaderBar,
    DataViewHeader
  }
}
</script>

<style lang="scss" scoped>
.layout-box {
  height: 100%;
  background: #f0f2f5;
}

.app-main-data-view {
  height: calc(100vh - 56px - 54px);
  width: 100%;
}
</style>
