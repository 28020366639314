import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/components/Layout' // 通用布局
import LayoutDataView from '@/components/Layout/DataView' // 数据视图布局

export const routes = [
  // 登录页面
  { path: '/login', name: 'login', component: () => import('@/views/login') },
  // 选择企业
  { path: '/selectPlatform', name: 'selectPlatform', component: () => import('@/views/login/selectPlatform') },
  {
    path: '/prodManage',
    component: Layout,
    name: 'prodManage',
    redirect: '/prodManage/index',
    meta: { title: '生产管理' },
    children: [
      {
        path: 'index',
        name: 'prodManageIndex',
        component: () => import('@/views/prodManage/index'),
        meta: { title: '首页' }
      },
      {
        path: 'concreteProd',
        name: 'concreteProd',
        component: () => import('@/views/prodManage/concrete_prod/stat'),
        meta: { title: '生产统计' }
      },
      {
        path: 'mortarProd',
        name: 'mortarProd',
        component: () => import('@/views/prodManage/mortar_prod/stat'),
        meta: { title: '生产统计' }
      },
      // 砂浆发货明细
      {
        path: 'shipDetailShaJiang',
        name: 'shipDetailShaJiang',
        component: () => import('@/views/prodManage/shipDetailShaJiang')
      },
      // 砂浆发货回单
      {
        path: 'receiptShaJiang',
        name: 'receiptShaJiang',
        component: () => import('@/views/prodManage/receiptShaJiang')
      }
    ]
  },
  {
    path: '/purchaseContract',
    name: 'purchaseContract',
    meta: { title: '采购合同' },
    component: Layout,
    redirect: '/purchaseContract/index',
    children: [
      /* {
        path: 'overSpeed',
        name: 'overSpeed',
        component: () => import('@/views/report/busReport/overSpeedReport'),
        meta: { title: '超速报表'}
      }, */
      {
        path: 'index',
        name: 'index',
        component: () => import('@/views/purchaseContract/purchaseContract'),
        meta: { title: '采购合同' }
      }
    ]
  },
  {
    path: '/qualityWarn',
    name: 'qualityWarn',
    meta: { title: '质量预警' },
    component: Layout,
    redirect: '/qualityWarn/comp',
    children: [
      {
        path: 'comp',
        name: 'comp',
        component: () => import('@/views/qualityWarn/qualityWarn'), // 企业端
        meta: { title: '质量预警' }
      },
      {
        path: 'man',
        name: 'man',
        component: () => import('@/views/qualityWarn/qualityWarn2'), // 管理端
        meta: { title: '质量预警' }
      }
    ]
  },
  { // 统计报表
    path: '/report',
    component: Layout,
    name: 'report',
    redirect: '/report/index',
    meta: { title: '统计报表' },
    children: [
      /* {
        path: 'overSpeed',
        name: 'overSpeed',
        component: () => import('@/views/report/busReport/overSpeedReport'),
        meta: { title: '超速报表'}
      }, */
      {
        path: 'mileage',
        name: 'mileage',
        component: () => import('@/views/report/busReport/mileageReport'),
        meta: { title: '里程报表' }
      }
    ]
  },
  {
    path: '/overSpeed',
    component: Layout,
    name: 'overSpeed',
    redirect: '/overSpeed/index',
    meta: { title: '超速管理' },
    children: [
      {
        path: 'record',
        name: 'record',
        component: () => import('@/views/report/busReport/overSpeedReport'),
        meta: { title: '超速记录' }
      }
    ]
  },
  { // 数据直报
    path: '/dataReport',
    component: Layout,
    name: 'dataReport',
    redirect: '/dataReport/index',
    meta: { title: '数据直报' },
    children: [
      { // 商砼
        path: 'concreteDay',
        name: 'concreteDay',
        component: () => import('@/views/dataReport/concreteDay'),
        meta: { title: '日报表' }
      },
      {
        path: 'concreteMonth',
        name: 'concreteMonth',
        component: () => import('@/views/dataReport/concreteMonth'),
        meta: { title: '月报表' }
      },
      {
        path: 'concreteYear',
        name: 'concreteYear',
        component: () => import('@/views/dataReport/concreteYear'),
        meta: { title: '年报表' }
      },
      { // 砂浆
        path: 'mortarDay',
        name: 'mortarDay',
        component: () => import('@/views/dataReport/mortarDay'),
        meta: { title: '日报表' }
      },
      {
        path: 'mortarMonth',
        name: 'mortarMonth',
        component: () => import('@/views/dataReport/mortarMonth'),
        meta: { title: '月报表' }
      },
      {
        path: 'mortarYear',
        name: 'mortarYear',
        component: () => import('@/views/dataReport/mortarYear'),
        meta: { title: '年报表' }
      },
      {
        path: 'device',
        name: 'device',
        component: () => import('@/views/dataReport/concreteDevice'),
        meta: { title: '设备维护' }
      },
      {
        path: 'noSubmitMonth',
        name: 'noSubmitMonth',
        component: () => import('@/views/dataReport/noSubmitMonth'),
        meta: { title: '未提交月报' }
      },
      {
        path: 'noSubmitYear',
        name: 'noSubmitYear',
        component: () => import('@/views/dataReport/noSubmitYear'),
        meta: { title: '未提交年报' }
      }
    ]
  },
  { // 质量管理
    path: '/quality',
    component: Layout,
    name: 'quality',
    redirect: '/quality/index',
    meta: { title: '质量管理' },
    children: [
      {
        path: 'mixManage',
        name: 'mixManage',
        component: () => import('@/views/qualityManage/concreteQuality/mixManage'),
        meta: { title: '配合比管理' }
      },
      {
        path: 'measureManage',
        name: 'measureManage',
        component: () => import('@/views/qualityManage/concreteQuality/measureManage'),
        meta: { title: '计量管理' }
      },
      {
        path: 'certOut',
        name: 'certOut',
        component: () => import('@/views/qualityManage/certificateOut/certificateOut'),
        meta: { title: '出厂合格证' }
      },
      // 混凝土检验单配置
      {
        path: 'qualityTestConfig',
        name: 'qualityTestConfig',
        component: () => import('@/views/qualityManage/concreteQuality/qualityTestConfig')
      },
      // 混凝土原材料质量检测
      {
        path: 'materialsQualityTest',
        name: 'materialsQualityTest',
        component: () => import('@/views/qualityManage/concreteQuality/materialsQualityTest')
      },
      // 混凝土厂检测
      {
        path: 'leaveQualityTest',
        name: 'leaveQualityTest',
        component: () => import('@/views/qualityManage/concreteQuality/leaveQualityTest')
      },
      // 混凝土入场检测
      {
        path: 'enterQualityTest',
        name: 'enterQualityTest',
        component: () => import('@/views/qualityManage/concreteQuality/enterQualityTest')
      },
      // 混凝土实体检测
      {
        path: 'entityQualityTest',
        name: 'entityQualityTest',
        component: () => import('@/views/qualityManage/concreteQuality/entityQualityTest')
      },
      // 生产质量配合比
      {
        path: 'productMixMan',
        name: 'productMixMan',
        component: () => import('@/views/qualityManage/productMixMan')
      }
    ]
  },
  // 数据视图模块
  {
    path: '/dataView',
    component: LayoutDataView,
    redirect: '/dataView/index',
    children: [
      // 总览
      {
        name: 'dataView',
        path: 'index',
        component: () => import('@/views/dataView')
      },
      // 预拌砂浆
      {
        name: 'dataViewShaJiang',
        path: 'shaJiang',
        component: () => import('@/views/dataView/shaJiang')
      },
      // 预拌混凝土
      {
        name: 'dataViewHunNingTu',
        path: 'hunNingTu',
        component: () => import('@/views/dataView/hunNingTu')
      },
      // 车辆监测
      {
        name: 'dataViewCarMonitor',
        path: 'carMonitor',
        component: () => import('@/views/dataView/carMonitor')
      },
      // 企业库视图
      {
        name: 'companyView',
        path: 'companyView',
        component: () => import('@/views/dataView/companyView'),
        meta: {
          subMenu: false,
          title: '企业库视图'
        }
      },
      // 项目库视图
      {
        name: 'projectView',
        path: 'projectView',
        component: () => import('@/views/dataView/projectView'),
        meta: {
          subMenu: false,
          title: '项目库视图'
        }
      }
    ]
  },
  // 实时监控模块
  {
    path: '/monitor',
    component: Layout,
    redirect: '/monitor/index',
    children: [
      // 首页
      {
        path: 'index',
        name: 'monitor',
        component: () => import('@/views/monitor/home')
      },
      // 定位监控
      {
        path: 'carMonitor',
        name: 'carMonitor',
        component: () => import('@/views/monitor/carMonitor'),
        meta: {
          webSocketConfigs: [24] // ws 推送车辆数据
        }
      },
      // 轨迹回放
      {
        path: 'carTrack',
        name: 'carTrack',
        component: () => import('@/views/monitor/carTrack')
      },
      // 场地视频
      {
        path: 'placeVideo',
        name: 'placeVideo',
        component: () => import('@/views/monitor/placeVideo')
      },
      // 车载视频
      {
        path: 'carVideo',
        name: 'carVideo',
        component: () => import('@/views/monitor/carVideo')
      },
      // 兴趣点
      {
        path: 'interestPoint',
        name: 'interestPoint',
        component: () => import('@/views/monitor/interestPoint')
      },
      {
        path: 'mortarMonitor',
        name: 'mortarMonitor',
        component: () => import('@/views/monitor/mortarMonitor/index'),
        meta: {
          webSocketConfigs: [25] // ws 推送砂浆数据
        }
      }
    ]
  },
  {
    path: '/authManage',
    component: Layout,
    redirect: '/authManage/index',
    children: [
      // 菜单管理
      {
        path: 'index',
        name: 'menu',
        component: () => import('@/views/authManage/menu')
      },
      // 账号管理
      {
        path: 'account',
        name: 'account',
        component: () => import('@/views/authManage/account')
      },
      // 角色管理
      {
        path: 'role',
        name: 'role',
        component: () => import('@/views/authManage/role')
      },
      // 平台管理
      {
        path: 'platform',
        name: 'platform',
        component: () => import('@/views/authManage/platform')
      },
      { // 分类数据
        path: 'itemManage',
        name: 'itemManage',
        component: () => import('@/views/authManage/itemManage')
      }
    ]
  },
  // 通知通告
  {
    path: '/notice',
    component: Layout,
    redirect: '/notice/index',
    children: [
      // 通知通告, type: 1:行业公告
      {
        path: 'index/:type',
        name: 'notice',
        component: () => import('@/views/notice')
      }
    ]
  },
  // 信息管理
  {
    path: '/infoManage',
    component: Layout,
    redirect: '/infoManage/organization',
    children: [
      // 机构管理
      {
        path: 'organization',
        name: 'organization',
        component: () => import('@/views/infoManage/organization')
      },
      // 驾驶员管理
      {
        path: 'driverManage',
        name: 'driverManage',
        component: () => import('@/views/infoManage/driverManage')
      },
      // 人员管理
      {
        path: 'personManage',
        name: 'personManage',
        component: () => import('@/views/infoManage/personManage')
      },
      // 外部人员(宏基需求)
      {
        path: 'outsidePersonManage',
        name: 'outsidePersonManage',
        component: () => import('@/views/infoManage/outsidePersonManage')
      },
      // 专业人员管理
      {
        path: 'professionalManage',
        name: 'professionalManage',
        component: () => import('@/views/infoManage/professionalManage')
      },
      // 工程管理
      {
        path: 'projectManage',
        name: 'projectManage',
        component: () => import('@/views/infoManage/projectManage')
      },
      // 企业基本信息
      {
        path: 'compBaseInfo',
        name: 'compBaseInfo',
        component: () => import('@/views/infoManage/enterpriseRecord/compBaseInfo')
      },
      // 授权企业
      {
        path: 'sqCompBaseInfo',
        name: 'sqCompBaseInfo',
        component: () => import('@/views/infoManage/enterpriseRecord/sqCompBaseInfo')
      },
      // 外部车辆栏目
      {
        path: 'busInfoManage2',
        name: 'busInfoManage2',
        component: () => import('@/views/infoManage/busInfo2')
      },
      // 车辆管理
      {
        path: 'busInfoManage',
        name: 'busInfoManage',
        component: () => import('@/views/infoManage/busInfo')
      },
      // 监控车辆
      {
        path: 'monitorBusInfo',
        name: 'monitorBusInfo',
        component: () => import('@/views/infoManage/monitor_busInfo')
      }
    ]
  },
  // 报警管理
  {
    path: '/warningManage',
    component: Layout,
    redirect: '/warningManage/warningMsg',
    children: [
      // 企业通知
      { path: 'warningMsg', name: 'warningMsg', component: () => import('@/views/warningManage/warningMsg') },
      // 报警记录
      { path: 'warningRecord', name: 'warningRecord', component: () => import('@/views/warningManage/warningRecord') }
    ]
  },
  // 分析评估
  {
    path: '/analysis',
    component: Layout,
    redirect: '/analysis/productivityWarning',
    children: [
      // 产能预警
      { path: 'productivityWarning', name: 'productivityWarning', component: () => import('@/views/analysis/productivityWarning') }
    ]
  },
  // 销售管理
  {
    path: '/saleManage',
    component: Layout,
    redirect: '/saleManage/customerMan',
    children: [
      // 客户管理
      {
        path: 'customerMan',
        name: 'customerMan',
        component: () => import('@/views/saleManage/customerMan')
      },
      // 砂浆合同管理
      {
        path: 'contractManMortar',
        name: 'contractManMortar',
        component: () => import('@/views/saleManage/contractManMortar')
      },
      // 砂浆销售结算
      {
        path: 'settlementMortar',
        name: 'settlementMortar',
        component: () => import('@/views/saleManage/settlementMortar/layout')
      },
      // 商砼合同管理
      {
        path: 'contractManConcrete',
        name: 'contractManConcrete',
        component: () => import('@/views/saleManage/contractManConcrete')
      }
    ]
  },
  // 价格管理
  {
    path: '/saleManage',
    component: Layout,
    redirect: '/saleManage/priceMan',
    children: [
      // 价格管理
      {
        path: 'priceMan',
        name: 'priceMan',
        component: () => import('@/views/priceManage/priceMan')
      },
      // 工程管理
      {
        path: 'projectMan',
        name: 'projectMan',
        component: () => import('@/views/priceManage/priceProjMan')
      }
    ]
  },

  // 库存管理
  {
    path: '/stock',
    component: Layout,
    children: [
      // 库存统计
      { path: 'stockStatistics', name: 'stockStatistics', component: () => import('@/views/stock/stockStatistics') },
      // 收发存月报
      { path: 'stockMonthlyReporting', name: 'stockMonthlyReporting', component: () => import('@/views/stock/stockMonthlyReporting') },
      // 地磅流水
      { path: 'weighbridgeJourna', name: 'weighbridgeJourna', component: () => import('@/views/stock/weighbridgeJourna') },
      // 平板信息
      { path: 'padInfo', name: 'padInfo', component: () => import('@/views/stock/padInfo') },
      // 平板-料罐信息
      { path: 'padTankInfo', name: 'padTankInfo', component: () => import('@/views/stock/padTankInfo') }
    ]
  },
  // 新版layout组件
  {
    path: '/base',
    component: Layout,
    children: [
      // 材料看板
      { path: 'materialView', name: 'materialView', component: () => import('@/views/stock/materialView') },
      // 价格分析
      { path: 'priceAnalysis', name: 'priceAnalysis', component: () => import('@/views/analysis/priceAnalysis') },
      // 价格分析基准设置
      { path: 'priceStandardConfig', name: 'priceStandardConfig', component: () => import('@/views/analysis/priceStandardConfig') },
      // 材料通告
      { path: 'materialNotice', name: 'materialNotice', component: () => import('@/views/notice/materialNotice') },
      // WebSocket 配置
      { path: 'webSocket', name: 'webSocket', component: () => import('@/views/authManage/webSocket') },
      // 数据字典
      {
        path: 'dataDictionary', name: 'dataDictionary', component: () => import('@/views/authManage/dataDictionary')
      }
    ]
  },
  // 采购管理
  {
    path: '/purchaseManage',
    component: Layout,
    redirect: '/purchaseManage/purchasePlan',
    children: [
      // 采购计划
      {
        path: 'purchasePlan',
        name: 'purchasePlan',
        component: () => import(/* webpackChunkName: 'purchasePlan' */'@/views/purchaseManage/purchasePlan/purchasePlan')
      },
      // 采购订单
      {
        path: 'purchaseOrder',
        name: 'purchaseOrder',
        component: () => import(/* webpackChunkName: 'purchaseOrder' */'@/views/purchaseManage/purchaseOrder/purchaseOrder')
      },
      // 预约单
      {
        path: 'purchaseAppointmentOrder',
        name: 'purchaseAppointmentOrder',
        component: () => import(/* webpackChunkName: 'purchaseOrder' */'@/views/purchaseManage/purchaseAppointmentOrder/purchaseAppointmentOrder')
      }
    ]
  },
  // 微应用
  {
    path: '/micro',
    component: Layout,
    children: [
      { path: '*', component: () => import(/* webpackChunkName: 'microContainer' */'@/views/microContainer') }
    ]
  },
  { path: '/noMenu', name: 'noMenu', component: () => import('@/views/exception/noMenu') },
  { path: '/error', name: 'error', component: () => import('@/views/exception/error') }
]

// function getNoHeaderRoutes (routes) {
//   // routes = JSON.parse(JSON.stringify(routes))
//   const usefulRoutes = routes.filter(item => item.children && item.children.length > 0)
//   const newRoutes = []
//   usefulRoutes.forEach(item => {
//     const prefixPath = '/simple' + item.path + '/'
//     const currRoutes = item.children.map((subItem) => {
//       const itemObj = {}
//       itemObj.path = prefixPath + subItem.path
//       itemObj.name = subItem.name + 'NoHeader'
//       itemObj.meta = subItem.meta
//       itemObj.component = subItem.component
//       return itemObj
//     })
//     newRoutes.push(...currRoutes)
//   })
//   console.log(usefulRoutes, 'routes')
//   console.log(newRoutes, '')
//   return newRoutes
// }

Vue.use(Router)
// 解决vue-router重复点菜单报错问题
const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

export default new Router({
  scrollBehavior: () => ({ y: 0 }),
  // routes: [...getNoHeaderRoutes(routes), ...routes]
  routes: routes
})
