<template>
  <div class="breadcrumb-box">
    <!-- 菜单隐藏开关 -->
    <i
      class="collapse-btn"
      :class="$store.state.settings.isCollapse? 'el-icon-s-unfold': 'el-icon-s-fold'"
      @click="changeMenuCollapse"
    ></i>
    <el-breadcrumb class="app-breadcrumb" separator="/">
      <transition-group name="breadcrumb" mode="out-in">
        <el-breadcrumb-item v-for="(item,index) in permPath" :key="item.permId">
          <span class="no-redirect" v-if="index==permPath.length-1">{{ item.permName }}</span>
          <a v-else @click.prevent="handleLink(item)">{{ item.permName }}</a>
        </el-breadcrumb-item>
      </transition-group>
    </el-breadcrumb>
  </div>
</template>

<script>
import XEUtils from 'xe-utils'
export default {
  computed: {
    permPath () {
      const perms = this.$store.state.account.perms
      const routePath = this.$route.fullPath
      const target = XEUtils.findTree(perms, (item) => item.accePath == routePath && item.permType == 1) || { nodes: [] }
      return target.nodes
    }
  },

  methods: {
    handleLink (item) {
      this.$router.push(item.accePath)
    },
    changeMenuCollapse () {
      const isCollapse = this.$store.state.settings.isCollapse
      this.$store.commit('settings/CHANGE_SETTING', {
        key: 'isCollapse',
        value: !isCollapse
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.breadcrumb-box {
  box-sizing: border-box;
  height: 55px;
  padding-left: 20px;
  background: #fff;
  display: flex;
  align-items: center;
}

.collapse-btn {
  font-size: 22px;
  cursor: pointer;
  &:hover {
    border-radius: 5px;
    opacity: .8;
  }
}

.app-breadcrumb.el-breadcrumb {
  margin-left: 15px;

  .no-redirect {
    cursor: text;
  }
}
</style>
