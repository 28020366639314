// 接入微前端
import microApp from '@micro-zoe/micro-app'
microApp.start({
  preFetchApps: [
    {
      name: 'micro',
      url: process.env.NODE_ENV === 'production' ? location.origin + '/micro' : 'http://localhost:8001/micro',
      disableScopecss: true,
      shadowDOM: true
    }
  ]
  // inline: true, // 默认值false
  // destroy: true, // 默认值false
  // disableScopecss: true, // 默认值false
  // disableSandbox: true, // 默认值false
  // shadowDOM: true // 默认值false
  // ssr: true // 默认值false
})
// microApp.start()
