import Mock from 'mockjs'
import { builder, getQueryParameters } from '../util'

const article = (options) => {
  const queryParameters = getQueryParameters(options) // 获取请求参数
  console.log(queryParameters, 'queryParameters')

  const data = Mock.mock({
    id: '@id()',
    name: '@cname()'
  })
  return builder(data)
}

// Mock.mock( rurl, function( options ) )
// 记录用于生成响应数据的函数。当拦截到匹配 rurl 的 Ajax 请求时，函数 function(options) 将被执行，并把执行结果作为响应数据返回。
Mock.mock('/list/article', 'get', article)
