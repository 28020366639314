import router from '@/router'
// 断线重连
import ReconnectingWebSocket from './ReconnectingWebSocket'
import store from '@/store/index.js'

let heartTimeout = 0
let webSocketIns = null
const messageLisner = new Map()
const IS_PRODUCTION = process.env.NODE_ENV === 'production'
// 本地调试也用 wss://jgzaio.wazert.com/unibu/imserver/
const protocal = location.protocol.includes('s') ? 'wss:' : (IS_PRODUCTION ? 'ws:' : 'wss:')
const wsUrl = IS_PRODUCTION ? `${protocal}//${location.host}/unibu/imserver/` : `${protocal}//jgzaio.wazert.com/unibu/imserver/`

/**
 * 如何在项目中使用websocket：
 * 1. 配置目标页面需要哪些数据推送：
 *    - 路由 meta 中配置 webSocketConfigs: [ type ]；type 需要与下方 types 中的 type 值一致；
 *    - 在下方 types 中配置获取目标数据需要发送的 sendMessage，值是后端需要的值；
 *
 * 2. 映射推送数据 和 type 的关系:
 *    - 比如：获取车辆数据 后端返回的是 data['data']，别的数据可能是 data['aaa']，需要在下方的 types 中定义好 type 和 prop 的关系；
 *
 * 3. 在需要使用 ws 数据的组件或页面中，调用 addMessageLisner(type, callback) 方法，以车辆数据为例：
 *  addMessageLisner(24, function(data) {
 *     console.log(data) // 车辆数据
 *  })
 */
const types = [
  // 车辆定位
  { type: 24, prop: 'data', sendMessage: '{ "msgEnum": "BUS" }' },
  { type: 25, prop: 'data2', sendMessage: '{ "msgEnum": "POT" }' }
]

router.beforeEach(async (to, from, next) => {
  // 如果上个页面开启了ws，这里关掉
  if (webSocketIns) {
    clearTimeout(heartTimeout)
    webSocketIns.close()
    webSocketIns = null
    messageLisner.clear()
  }

  // 路由的 meta 中配置了 webSockets 才执行
  const webSocketConfigs = to.meta.webSocketConfigs
  if (webSocketConfigs && webSocketConfigs.length > 0) {
    const { token } = store.state.account.loginInfo

    // 连接新的ws
    webSocketIns = new ReconnectingWebSocket(wsUrl + token)

    webSocketIns.onmessage = function ({ data }) {
      // 连接成功，告知后端推送哪些数据过来
      if (data === 'success') {
        // 根据路由中的配置webSocketConfigs决定发送哪些信息
        webSocketConfigs.forEach((type) => {
          const target = types.find((item) => item.type == type)
          if (target) {
            webSocketIns.send(target.sendMessage)
          }
        })

        // 发送心跳
        clearTimeout(heartTimeout)
        sendHeart(webSocketIns)
      } else if (data == 'h') {
        console.log(data, 'heart')
      // 返回的内容不包含 success，则是返回的数据对象
      } else if (!data.includes('success')) {
        data = JSON.parse(data)
        const target = types.find(({ prop }) => !!data[prop])
        if (target) {
          // 收集页面或组件中注册的回调，推送数据过来统一触发，并把推送的数据传递到回调中；
          const { prop, type } = target
          const callbacks = messageLisner.get(type)
          if (callbacks) {
            callbacks.forEach(call => {
              call && call(data[prop])
            })
          }
        }
      }
    }
  }

  next()
})

// 添加推送触发的行为: 收集页面或组件中注册的回调，推送数据过来统一触发，并把推送的数据传递到回调中；
export function addMessageLisner (type, callback) {
  if (messageLisner.get(type)) {
    messageLisner.get(type).push(callback)
  } else {
    messageLisner.set(type, [callback])
  }
}

// 移除推送触发的行为
export function removeMessageLisner (clientId, callback) {
  const callbacks = messageLisner.get(clientId)
  if (callbacks) {
    const index = callbacks.indexOf(callback)
    if (index !== -1) {
      callbacks.splice(index, 1)
    }
  }
}

export function sendHeart (webSocketIns) {
  if (WebSocket.OPEN == webSocketIns.readyState) {
    webSocketIns.send('{ "msgEnum": "HEART" }')
    heartTimeout = setTimeout(() => {
      sendHeart(webSocketIns)
    }, 30000)
  }
}
