
<script>
import XEUtils from 'xe-utils'
export default {
  data () {
    return {
      span: 0,
      isExpand: false
    }
  },
  render () {
    const defaultSlots = this.$slots.default
    const attrs = this.$attrs
    const listeners = this.$listeners
    // 视口宽度大于1500，一个筛选条件占6栅栏格，小于1500占8格
    this.span = window.innerWidth > 1500 ? 6 : 8
    // 元素铺满一行（加搜索按钮会换行，需要折叠功能）
    const isShowExpand = defaultSlots.length >= 24 / this.span
    return (
      <el-form size="small" class="search-form-wrapper bg-white" inline={true} submit_native_prevent>
        <el-row gutter={16}>
          {
            defaultSlots.map((item, index) => {
              // 区分默认展示 和 需要折叠的元素
              if (index < 24 / this.span - 1) {
                return <el-col span={this.span}>{item}</el-col>
              } else {
                return <el-col vShow={this.isExpand} span={this.span}>{item}</el-col>
              }
            })
          }
          <el-col span={this.span} >
            <div class="mb-10">
              <el-button vShow={listeners.search} type="primary" size="small" native-type="submit" vOn:click={this.handleSearch} icon="el-icon-search" loading={attrs.loading}>搜 索</el-button>
              <el-button vShow={listeners.reset} size="small" vOn:click={this.handleReset} icon="el-icon-refresh" loading={attrs.loading}>重 置</el-button>
              {
                // 展示收起按钮
                <el-button
                  vShow={isShowExpand}
                  type="text"
                  vOn:click={this.handleExpand}
                  class="ml-20">
                  {this.isExpand ? '收起' : '展开'}
                  <i class={this.isExpand ? 'el-icon-arrow-up ml-5' : 'el-icon-arrow-down ml-5'}></i>
                </el-button>
              }
            </div>
          </el-col>
        </el-row>
      </el-form>
    )
  },
  mounted () {
    this.initAutoResizeWidth()
  },
  methods: {
    handleExpand () {
      this.isExpand = !this.isExpand
    },
    initAutoResizeWidth () {
      window.addEventListener('resize', this.changeSearchBarWidth)
      this.$once('hook:beforeDestroy', () => {
        window.removeEventListener('resize', this.changeSearchBarWidth)
      })
    },
    changeSearchBarWidth: XEUtils.debounce(function () {
      this.span = window.innerWidth > 1500 ? 6 : 8
    }, 400),
    handleSearch () {
      this.$emit('search')
    },
    handleReset () {
      this.$emit('reset')
    }
  }
}
</script>

<style scoped lang="scss">
.search-form-wrapper {
  padding: 15px;
  padding-bottom: 5px;
  margin-bottom: 15px;
}
::v-deep {
  .el-form-item {
    margin-bottom: 10px!important;
    margin-right: 0!important;
    display: flex!important;
    .el-form-item__content {
      flex: 1!important;
    }
    .el-form-item__content > [class^="el-"] {
      width: 100%!important;
    }
    .el-form-item__label {
      color: #000000D9;
    }
  }
}
</style>
