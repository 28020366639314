import http from '@/utils/http'

// 获取图形验证码
export function getVerifyCode (p) {
  return http.post('/mortconc/codecs/gainImagVeri', p)
}

// 登入
export function login (p) {
  return http.post('/mortconc/logincs/verify', p)
}

// 登出
export function logout (p) {
  // 路由改变不取消请求
  return http.post('/mortconc/logincs/exit', p, false)
}

// 获取用户信息
export function getUserInfo (p) {
  // 因为小程序的权限和web的权限做在一起了，accoByWebReq是过滤掉杭商砼小程序的权限
  return http.post('/mortconc/logincs/accoByWebReq', p)
}

// 获取用户权限(菜单)列表
export function getUserMenus (p) {
  return http.post('/mortconc/logincs/entRolPerByReq', p)
}

// 刷新token
export function refreshToken (p) {
  return http.post('/mortconc/logincs/renovate', p)
}
