import http from '@/utils/http'

// 数据字典：获取列表
export function getDictionaryList (p) {
  return http.post('/unibu/dd/page', p)
}

// 数据字典：新增或修改
export function updateDictionary (p) {
  return http.post('/unibu/dd/addMod', p)
}

// 数据字典：删除
export function delDictionary (p) {
  return http.post('/unibu/dd/del', p)
}

// 获取字典数据
export function getDictionary (p) {
  return http.get(`/unibu/dd/${p.dictCode}`, p)
}

// 数据字典：启用禁用
export function enableDictionary (p) {
  return http.post('/unibu/dd/enable', p)
}

// 数据字典：启用禁用
export function refreshDictCache () {
  return http.post('/basefun/basecs/selectDict', {
    isFlush: true
  })
}
