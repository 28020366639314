function isIE () {
  const bw = window.navigator.userAgent
  const compare = (s) => bw.indexOf(s) >= 0
  const ie11 = (() => 'ActiveXObject' in window)()
  return compare('MSIE') || ie11
}

// 判断环境不是 prod 或者 preview 是 true 时，加载 mock 服务
if (process.env.VUE_APP_PREVIEW === 'development') {
  if (isIE()) {
    console.error('ERROR: `mockjs` NOT SUPPORT `IE` PLEASE DO NOT USE IN `production` ENV.')
  }

  // 这里最好用require，import是异步的，避免vue中调用接口时，mock还未初始化
  const Mock = require('mockjs')
  require('./services/userInfo')

  Mock.setup({
    timeout: 800 // setter delay time
  })
}
