<template>
  <div class="header-bar" :style="{backgroundColor:theme}">
    <!-- 企业logo -->
    <div class="flex align-center justify-center h-100-p ml-15 mr-15" style="min-width:190px;">
      <img v-if="systemLogo" class="system-logo" :src="getMediaUrl(systemLogo)">
      <span class="white fs-16" style="user-select:none;margin:0 4px;opacity:.95;">{{getSystemName()}}</span>
    </div>

    <!-- 一级菜单列表 -->
    <scroll-view class="flex-1" ref="scrollRef">
      <router-link
        ref="scrollItem"
        :class="['menu-item flex flex-column align-center justify-around',{'active':menu.activePaths&&menu.activePaths.includes($route.fullPath)}]"
        v-for="menu in menuList"
        :key="menu.permId"
        :to="menu.accePath">
        <img v-if="menu.permPicURL0" class="w-30" :src="getMediaUrl(menu.permPicURL0)">
        <p class="mb-2">{{menu.permName}}</p>
      </router-link>
    </scroll-view>

    <!-- 右侧功能列表 -->
    <div class="right-func-list ml-15">
      <el-dropdown trigger="click" size="medium" placement="bottom">
        <div class="flex align-center white">
          <img class="avatar" src="@/assets/imgs/common/avatar.png">
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item icon="el-icon-orange" @click.native="themeVisible=true">主题设置</el-dropdown-item>
          <el-dropdown-item icon="el-icon-full-screen" @click.native="fullscreen">系统全屏</el-dropdown-item>
          <el-dropdown-item icon="el-icon-switch-button" @click.native="logout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <span class="fs-12 ml-10 white mr-15" v-if="userName">当前用户：{{userName}}</span>
      <div class="weather" @click="showWeather" v-if="weatherRes != undefined && weatherRes != null">
        <div>{{weatherRes.tempCurr}}℃</div>
        <div>{{weatherRes.cityName}}</div>
        <div> <img :src="getWeatherIcon(this.weatherRes.weatherCur)" class="weather-img"/></div>
        <div>{{weatherRes.aqiEvaluate}}</div>
      </div>
    </div>

    <!-- 主题dialog -->
    <el-dialog
      v-dialogDrag
      title="主题设置"
      width="350px"
      :visible.sync="themeVisible">
      <el-form label-width="105px">
        <el-form-item label="系统主题色">
          <theme-picker @change="themeChange"></theme-picker>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      v-if="weatherRes != undefined && weatherRes != null"
      v-dialogDrag
      title="天气预报"
      width="600px"
      class="weather-dialog"
      :visible.sync="weatherVisible">
      <div>
        <div class="weather-pad" style="background-color: #0c85dc;width:100%;
    height: 100%;
    display: flex;
    flex-direction: column;">
          <div style="color:white;font-size:28px;font-weight: bold;padding: 15px">{{weatherRes.cityName}}</div>
          <div style="display: flex; color:white;padding:15px;">
            <div style="margin-right:15px">{{weatherRes.updateDate}}</div>
            <div style="margin-right:15px">{{weatherRes.updateWeekInfo}}</div>
            <!--            <div>{{weatherRes.updateDate}}</div>-->
          </div>
          <div style="display: flex; color:white;padding:15px;">
            <div> <img :src="getWeatherIcon(this.weatherRes.weatherCur)"/></div>
            <div style="display: flex;flex-direction: row;padding:20px;"><div style="font-size:80px;">{{weatherRes.tempCurr}}</div><div style="font-size:30px;">℃</div></div>
            <div style="display: flex;flex-direction: column;padding:20px;">
              <div style="font-size:32px;">{{weatherRes.weatherInfo}}</div>
              <div style="font-size:22px;margin-top:20px;">{{weatherRes.tempMin}}~{{weatherRes.tempMax}}℃</div>
            </div>
          </div>
          <div style="display: flex; color:white;padding:15px;">
            <div style="margin-right:15px">空气质量：{{weatherRes.aqiEvaluate}}</div>
            <div style="margin-right:15px">风向：{{weatherRes.windDirecInfo}}{{weatherRes.windSpeedInfo}}</div>
            <div>适宜活动：{{weatherRes.notice}}</div>
          </div>
        </div>
        <div style="padding-top:15px;padding-bottom:10px;font-size: 20px;color:black">未来一周天气预报</div>
        <div style="background-color: #0c85dc;width:100%;height: 100%;display:flex;flex-direction:row;flex-wrap:wrap;border-radius: 5px;border: solid 1px #000;background: #eee;">
          <div v-for="(item,index) in weatherFutrueArr" :key="index" style="display: flex;flex-direction: column;padding:15px;">
            <div style="text-align: center;color:black;">{{item.updateWeekInfo}}</div>
            <div style="text-align: center;margin-top:5px">{{item.updateDate}}</div>
            <div style="text-align: center;margin-top:20px;margin-bottom: 20px;"><img :src="getWeatherIcon(item.weatherInfo)" style="width:40px;height: 40px;"/></div>
            <div style="text-align: center">{{item.tempMin}}~{{item.tempMax}}℃</div>
            <div style="text-align: center;color:black;margin-top:5px;">{{item.windDirecInfo}}{{item.windSpeedInfo}}</div>
          </div>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import screenfull from 'screenfull'
import ThemePicker from './ThemePicker'
import ScrollView from './ScrollView.vue'
import { logout } from '@/apis/login'
import { mapState } from 'vuex'
import { getSystemName } from '@/utils/settings.js'
export default {
  data () {
    return {
      themeVisible: false,
      weatherCurIcon: '',
      weatherVisible: false
    }
  },
  mounted () {
    // if (!window.WIDGET) {
    //   window.WIDGET = {
    //     CONFIG: {
    //       modules: '0124',
    //       background: '1',
    //       tmpColor: 'FFFFFF',
    //       tmpSize: '12',
    //       cityColor: 'FFFFFF',
    //       citySize: '12',
    //       aqiColor: 'FFFFFF',
    //       aqiSize: '12',
    //       weatherIconSize: '18',
    //       alertIconSize: '16',
    //       padding: '10px 10px 10px 10px',
    //       shadow: '0',
    //       language: 'auto',
    //       fixed: 'false',
    //       vertical: 'middle',
    //       horizontal: 'left',
    //       key: '18240e2cf8624ed38abdfb02b869280f'
    //     }
    //   }
    //   const script = document.createElement('script')
    //   script.type = 'text/javascript'
    //   script.src = 'https://widget.qweather.net/simple/static/js/he-simple-common.js?v=2.0'
    //   document.getElementsByTagName('head')[0].appendChild(script)
    // }
  },
  computed: {
    ...mapState('settings', ['theme']),
    weatherRes () {
      return this.$store.state.account.todayWeather
    },
    weatherFutrueArr () {
      return this.$store.state.account.futureWeather
    },
    menuList () {
      return this.$store.state.account.perms
    },
    userName () {
      return this.$store.state.account.loginInfo.loginName
    },
    systemLogo () {
      const roles = this.$store.state.account.userInfo.roles
      let logoUrl = ''
      roles.some(({ sysPicURL }) => {
        if (sysPicURL) {
          logoUrl = sysPicURL
          return true
        } else {
          return false
        }
      })
      return logoUrl
    }
  },
  methods: {
    showWeather () {
      this.weatherVisible = true
    },
    getWeatherIcon (weatherCur) {
      const iconWeatherArr = [{
        iconUrl: require('@/assets/imgs/weather/Windy.png'),
        nameArr: ['有风', '平静', '微风', '和风', '清风', '强风/劲风', '疾风', '大风', '烈风', '风暴', '狂爆风', '飓风', '热带风暴', '龙卷风']
      },
      {
        iconUrl: require('@/assets/imgs/weather/MostlySunny.png'),
        nameArr: ['少云', '晴间多云', '多云']
      },
      {
        iconUrl: require('@/assets/imgs/weather/Snow.png'),
        nameArr: ['雪', '阵雪', '小雪', '中雪', '大雪', '暴雪', '小雪-中雪', '中雪-大雪', '大雪-暴雪', '冷']
      },
      {
        iconUrl: require('@/assets/imgs/weather/Dust.png'),
        nameArr: ['浮尘', '扬沙', '沙尘暴', '强沙尘暴', '雾', '浓雾', '强浓雾', '轻雾', '大雾', '特强浓雾']
      },
      {
        iconUrl: require('@/assets/imgs/weather/Sun.png'),
        nameArr: ['晴', '热']
      },
      {
        iconUrl: require('@/assets/imgs/weather/IcySnow.png'),
        nameArr: ['雨雪天气', '雨夹雪', '阵雨夹雪']
      },
      {
        iconUrl: require('@/assets/imgs/weather/Rain.png'),
        nameArr: ['阵雨', '雷阵雨', '雷阵雨并伴有冰雹', '小雨', '中雨', '大雨', '暴雨', '大暴雨', '特大暴雨', '强阵雨', '强雷阵雨', '极端降雨', '毛毛雨/细雨', '雨', '小雨-中雨', '中雨-大雨', '大雨-暴雨', '暴雨-大暴雨', '大暴雨-特大暴雨', '冻雨']
      },
      {
        iconUrl: require('@/assets/imgs/weather/Cloudy.png'),
        nameArr: ['阴', '霾', '中度霾', '重度霾', '严重霾', '未知']
      }
      ]
      const res = iconWeatherArr.filter(item => item.nameArr.includes(weatherCur))
      return res[0].iconUrl
    },
    fullscreen () {
      if (!screenfull.enabled) {
        this.$message({
          message: '当前浏览器不支持全屏功能',
          type: 'warning'
        })
        return false
      }
      screenfull.toggle()
    },

    getSystemName () {
      return getSystemName()
    },

    themeChange (val) {
      this.$store.dispatch('settings/changeSetting', {
        key: 'theme',
        value: val
      })
    },

    changeMenuCollapse () {
      const isCollapse = this.$store.state.settings.isCollapse
      this.$store.commit('settings/CHANGE_SETTING', {
        key: 'isCollapse',
        value: !isCollapse
      })
    },

    // 注销token，清除account信息
    logout () {
      // selectedComp({focusType:2})
      logout()
      this.$store.dispatch('account/clearAcountInfo').then(() => {
        this.$router.push('/login')
      })
    }
  },
  components: {
    ThemePicker,
    ScrollView
  }
}
</script>

<style lang="scss" scoped>
.header-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all .3s;
  padding: 0 20px 0 0;
  height: 56px;
  position: relative;
  z-index: 999;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
  .system-logo {
    max-height: 56px;
    margin: 0 4px;
    object-fit: contain;
  }
  .menu-item {
    min-width: 100px;
    padding: 0 10px;
    height: 56px;
    text-align: center;
    color: #DFDFED;
    font-size: 14px;
    cursor: pointer;
    user-select: none;
    &.active,
    &:hover {
      color:#FFFFFF;
      background-image: url('~@/assets/imgs/common/menu-bg.png');
    }
  }
  .right-func-list {
    display: flex;
    align-items: center;
    .avatar {
      width: 26px;
      height: 26px;
      cursor: pointer;
    }
    .weather{
      width:130px;
      height:36px;
      background-image: url('~@/assets/imgs/common/weather_icon.jpeg');
      display: flex;
      justify-content: space-between;
      align-items: center;
      color:white;
      font-size:10px;
      .weather-img{
        width:20px;
        height: 20px;

    }
    }
  }

  .avatar,
  #screenfull {
    &:hover {
      border-radius: 5px;
      background-image: url('~@/assets/imgs/common/menu-bg.png');
    }
  }
  .weather-pad{
    background-color: #0c85dc;
    width:100%;
    height: 100%;
    display: flex;
    flex-direction: row;
  }

}
</style>
