<template>
  <div ref="wrapperRef">
    <slot v-bind:height="height"></slot>
  </div>
</template>

<script>
import XEUtils from 'xe-utils'
export default {
  data () {
    return {
      height: 0
    }
  },
  mounted () {
    const wrapperRef = this.$refs.wrapperRef
    this.height = wrapperRef.clientHeight || 0

    // 防抖
    const debounced = XEUtils.debounce((entries) => {
      this.height = wrapperRef.clientHeight || 0
    }, 500)

    // 观察div尺寸发生改变
    const resizeObserver = new ResizeObserver(debounced)
    resizeObserver.observe(this.$refs.wrapperRef)

    this.$once('hook:beforeDestory', () => {
      ResizeObserver.disconnect()
    })
  }
}
</script>
