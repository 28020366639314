<template>
  <vxe-table
    ref="innerTable"
    resizable
    :export-config="{}"
    border
    stripe
    show-overflow="title"
    highlight-hover-row
    highlight-current-row
    :size="size"
    :align="align"
    :headerCellStyle="headerCellStyle"
    v-on="$listeners"
    v-bind="$attrs">
    <slot></slot>
  </vxe-table>
</template>
<script>
export default {
  props: {
    size: { default: 'mini' },
    align: { default: 'center' },
    headerCellStyle: { default () { return { 'background-color': '#F9FAFE' } } }
  },
  methods: {
    // setAllTreeExpand(...params) {
    //   return this.$refs.innerTable.setAllTreeExpand(...params)
    // },
  }
}
</script>

<style>

</style>
