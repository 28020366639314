import server from '@/utils/initRequest'
import axios from 'axios'

// 存放取消请求的实例
let key = 0
export const cancels = new Map()
const CancelToken = axios.CancelToken

const http = {
  baseUrl: location.origin,

  get (url, params, cancelFlag = true) {
    const id = key++
    return server.get(url, {
      params,
      cancelToken: new CancelToken(function executor (c) {
        if (cancelFlag) {
          // 取消请求执行 c 即可
          cancels.set(id, c)
        }
      })

    }).finally(() => {
      if (cancelFlag && cancels.has(id)) {
        cancels.delete(id)
      }
    })
  },

  /**
   * post 请求
   * @param url 求其地址
   * @param params 请求参数
   * @returns {Promise}
  */
  post (url, params, cancelFlag = true) {
    const id = key++
    return server.post(url, params, {
      headers: { 'Content-Type': 'application/json;charset=utf-8' },
      cancelToken: new CancelToken(function executor (c) {
        if (cancelFlag) {
          // 取消请求执行 c 即可
          cancels.set(id, c)
        }
      })

    }).finally(() => {
      if (cancelFlag && cancels.has(id)) {
        cancels.delete(id)
      }
    })
  },

  postBlob (url, params) {
    return server.post(url, params, {
      responseType: 'blob',
      headers: { 'Content-Type': 'application/json;charset=utf-8' }
    })
  },

  postForm (url, formData) {
    return server.post(url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 120000
    })
  },

  getFormExcel (url, formData) {
    return server.get(url, formData, {
      timeout: 120000,
      responseType: 'blob'
    })
  },

  postFormExcel (url, params) {
    return server.post(url, params, {
      timeout: 15000,
      responseType: 'blob'
    })
  }
}

export default http

// 取消cancels中的所有请求
export function cancelAll () {
  for (const cancel of cancels.values()) {
    cancel()
  }
}
